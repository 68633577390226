var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "card",
    { staticClass: "main-content" },
    [
      _c("meta-utils-table", {
        attrs: {
          type: "hover table-bordered",
          adminId: _vm.adminId,
          accountId: _vm.accountId,
          allUtils: _vm.allUtils,
          data: _vm.table.data,
          currentNumber: _vm.allUtils.length
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }