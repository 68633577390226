var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("card", { staticClass: "main-content" }, [
    _c("div", { staticClass: "row mt-4 my-2" }, [
      _c("div", { staticClass: "col-3 my-2" }, [
        _vm._v("\n      " + _vm._s(_vm.$t("__webhookUrl")) + "\n    ")
      ]),
      _c("div", { staticClass: "col-9 my-2" }, [
        _c("span", [_vm._v(_vm._s(_vm.webhookUrl))]),
        _vm.isSupportClipboardApi()
          ? _c(
              "span",
              {
                on: {
                  click: function($event) {
                    return _vm.onClickCopyText(_vm.webhookUrl)
                  }
                }
              },
              [_c("i", { staticClass: "fas fa-copy cursor-pointer mx-2" })]
            )
          : _vm._e()
      ])
    ]),
    _c("div", { staticClass: "row mt-4 my-2" }, [
      _c("div", { staticClass: "col-3 my-2" }, [
        _vm._v("\n      " + _vm._s(_vm.$t("__accountId")) + "\n    ")
      ]),
      _c("div", { staticClass: "col-9 my-2" }, [
        _c("span", [_vm._v(_vm._s(_vm.accountId))])
      ])
    ]),
    _c("div", { staticClass: "row mt-4 my-2" }, [
      _c("div", { staticClass: "col-3 my-2" }, [
        _vm._v("\n      " + _vm._s(_vm.$t("__ConnectionType")) + "\n    ")
      ]),
      _c("div", { staticClass: "col-2 my-2" }, [
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.accountType,
                expression: "accountType"
              }
            ],
            staticClass: "custom-select",
            attrs: { id: "account-type", disabled: "" },
            on: {
              change: function($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function(o) {
                    return o.selected
                  })
                  .map(function(o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.accountType = $event.target.multiple
                  ? $$selectedVal
                  : $$selectedVal[0]
              }
            }
          },
          [
            _c("option", { attrs: { value: "line" } }, [
              _vm._v(_vm._s(_vm.$t("__line")))
            ]),
            _c("option", { attrs: { value: "wechat" } }, [
              _vm._v(_vm._s(_vm.$t("__wechat")))
            ]),
            _c("option", { attrs: { value: "fb" } }, [
              _vm._v(_vm._s(_vm.$t("__facebook")))
            ]),
            _c("option", { attrs: { value: "ig" } }, [
              _vm._v(_vm._s(_vm.$t("__instagram")))
            ])
          ]
        )
      ])
    ]),
    _vm.accountType == "line"
      ? _c("div", { attrs: { id: "line-block" } }, [
          _c("div", { staticClass: "row mt-4 my-2" }, [
            _c("div", { staticClass: "col-3 my-2" }, [
              _vm._v(_vm._s(_vm.$t("__channelSecret_MsgAPI")))
            ]),
            _c("div", { staticClass: "row col-9 my-2" }, [
              _c(
                "p",
                {
                  staticClass: "mb-2 col-9",
                  attrs: { id: "line-appsecret-title" }
                },
                [_vm._v(_vm._s(_vm.appSecret))]
              ),
              _c(
                "span",
                {
                  attrs: { id: "line-appsecret-edit" },
                  on: {
                    click: function($event) {
                      return _vm.editSecret()
                    }
                  }
                },
                [
                  _c("i", {
                    staticClass: "fas fa-pencil-alt cursor-pointer pl-1"
                  })
                ]
              ),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.appSecret,
                    expression: "appSecret"
                  }
                ],
                staticClass: "form-control mb-2 col-9 element-hide",
                attrs: { type: "text", id: "line-appsecret-input" },
                domProps: { value: _vm.appSecret },
                on: {
                  keypress: function($event) {
                    return _vm.confirmChangeSecret($event)
                  },
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.appSecret = $event.target.value
                  }
                }
              })
            ])
          ]),
          _c("div", { staticClass: "row mt-4 my-2" }, [
            _c("div", { staticClass: "col-3 my-2" }, [
              _vm._v(_vm._s(_vm.$t("__channelAccessToken_MsgAPI")))
            ]),
            _c("div", { staticClass: "row col-9 my-2" }, [
              _c(
                "p",
                {
                  staticClass: "mb-2 col-9",
                  attrs: { id: "line-apptoken-title" }
                },
                [_vm._v(_vm._s(_vm.appToken))]
              ),
              _c(
                "span",
                {
                  attrs: { id: "line-apptoken-edit" },
                  on: {
                    click: function($event) {
                      return _vm.editToken()
                    }
                  }
                },
                [
                  _c("i", {
                    staticClass: "fas fa-pencil-alt cursor-pointer pl-1"
                  })
                ]
              ),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.appToken,
                    expression: "appToken"
                  }
                ],
                staticClass: "form-control mb-2 col-9 element-hide",
                attrs: { type: "text", id: "line-apptoken-input" },
                domProps: { value: _vm.appToken },
                on: {
                  keypress: function($event) {
                    return _vm.confirmChangeToken($event)
                  },
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.appToken = $event.target.value
                  }
                }
              })
            ])
          ]),
          _c("div", { staticClass: "row mt-4 my-2" }, [
            _c("div", { staticClass: "col-3 my-2" }, [
              _vm._v(_vm._s(_vm.$t("__channelId_LineLogin")))
            ]),
            _c("div", { staticClass: "row col-9 my-2" }, [
              _c(
                "p",
                {
                  staticClass: "mb-2 col-9",
                  attrs: { id: "line-loginchannelid-title" }
                },
                [_vm._v(_vm._s(_vm.loginChannelId))]
              ),
              _c(
                "span",
                {
                  attrs: { id: "line-loginchannelid-edit" },
                  on: {
                    click: function($event) {
                      return _vm.editLoginChannelId()
                    }
                  }
                },
                [
                  _c("i", {
                    staticClass: "fas fa-pencil-alt cursor-pointer pl-1"
                  })
                ]
              ),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.loginChannelId,
                    expression: "loginChannelId"
                  }
                ],
                staticClass: "form-control mb-2 col-9 element-hide",
                attrs: { type: "text", id: "line-loginchannelid-input" },
                domProps: { value: _vm.loginChannelId },
                on: {
                  keypress: function($event) {
                    return _vm.confirmChangeLoginChannelId($event)
                  },
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.loginChannelId = $event.target.value
                  }
                }
              })
            ])
          ]),
          _c("div", { staticClass: "row mt-4 my-2" }, [
            _c("div", { staticClass: "col-3 my-2" }, [
              _vm._v(_vm._s(_vm.$t("__channelSecret_LineLogin")))
            ]),
            _c("div", { staticClass: "row col-9 my-2" }, [
              _c(
                "p",
                {
                  staticClass: "mb-2 col-9",
                  attrs: { id: "line-loginsecret-title" }
                },
                [_vm._v(_vm._s(_vm.loginSecret))]
              ),
              _c(
                "span",
                {
                  attrs: { id: "line-loginsecret-edit" },
                  on: {
                    click: function($event) {
                      return _vm.editLoginSecret()
                    }
                  }
                },
                [
                  _c("i", {
                    staticClass: "fas fa-pencil-alt cursor-pointer pl-1"
                  })
                ]
              ),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.loginSecret,
                    expression: "loginSecret"
                  }
                ],
                staticClass: "form-control mb-2 col-9 element-hide",
                attrs: { type: "text", id: "line-loginsecret-input" },
                domProps: { value: _vm.loginSecret },
                on: {
                  keypress: function($event) {
                    return _vm.confirmChangeLoginSecret($event)
                  },
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.loginSecret = $event.target.value
                  }
                }
              })
            ])
          ]),
          _c("div", { staticClass: "row mt-4 my-2" }, [
            _c("div", { staticClass: "col-3 my-2" }, [_vm._v("LIFF ID")]),
            _c("div", { staticClass: "row col-9 my-2" }, [
              _c(
                "p",
                {
                  staticClass: "mb-2 col-9",
                  attrs: { id: "line-liffid-title" }
                },
                [_vm._v(_vm._s(_vm.liffId))]
              )
            ])
          ])
        ])
      : _vm._e(),
    _vm.accountType == "wechat"
      ? _c("div", { attrs: { id: "wechat-block" } }, [
          _c("div", { staticClass: "row mt-4 my-2" }, [
            _c("div", { staticClass: "col-3 my-2" }, [_vm._v("appid")]),
            _c("div", { staticClass: "row col-9 my-2" }, [
              _c(
                "p",
                {
                  staticClass: "mb-2 col-7",
                  attrs: { id: "wechat-appid-title" }
                },
                [_vm._v(_vm._s(_vm.appId))]
              ),
              _c(
                "span",
                {
                  attrs: { id: "wechat-appid-edit" },
                  on: {
                    click: function($event) {
                      return _vm.editId()
                    }
                  }
                },
                [
                  _c("i", {
                    staticClass: "fas fa-pencil-alt cursor-pointer pl-1"
                  })
                ]
              ),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.appId,
                    expression: "appId"
                  }
                ],
                staticClass: "form-control mb-2 col-8 element-hide",
                attrs: { type: "text", id: "wechat-appid-input" },
                domProps: { value: _vm.appId },
                on: {
                  keypress: function($event) {
                    return _vm.confirmChangeId($event)
                  },
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.appId = $event.target.value
                  }
                }
              })
            ])
          ]),
          _c("div", { staticClass: "row mt-4 my-2" }, [
            _c("div", { staticClass: "col-3 my-2" }, [_vm._v("app token")]),
            _c("div", { staticClass: "row col-9 my-2" }, [
              _c(
                "p",
                {
                  staticClass: "mb-2 col-7",
                  attrs: { id: "wechat-apptoken-title" }
                },
                [_vm._v(_vm._s(_vm.appToken))]
              ),
              _c(
                "span",
                {
                  attrs: { id: "wechat-apptoken-edit" },
                  on: {
                    click: function($event) {
                      return _vm.editToken()
                    }
                  }
                },
                [
                  _c("i", {
                    staticClass: "fas fa-pencil-alt cursor-pointer pl-1"
                  })
                ]
              ),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.appToken,
                    expression: "appToken"
                  }
                ],
                staticClass: "form-control mb-2 col-8 element-hide",
                attrs: { type: "text", id: "wechat-apptoken-input" },
                domProps: { value: _vm.appToken },
                on: {
                  keypress: function($event) {
                    return _vm.confirmChangeToken($event)
                  },
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.appToken = $event.target.value
                  }
                }
              })
            ])
          ]),
          _c("div", { staticClass: "row mt-4 my-2" }, [
            _c("div", { staticClass: "col-3 my-2" }, [_vm._v("app secret")]),
            _c("div", { staticClass: "row col-9 my-2" }, [
              _c(
                "p",
                {
                  staticClass: "mb-2 col-7",
                  attrs: { id: "wechat-appsecret-title" }
                },
                [_vm._v(_vm._s(_vm.appSecret))]
              ),
              _c(
                "span",
                {
                  attrs: { id: "wechat-appsecret-edit" },
                  on: {
                    click: function($event) {
                      return _vm.editSecret()
                    }
                  }
                },
                [
                  _c("i", {
                    staticClass: "fas fa-pencil-alt cursor-pointer pl-1"
                  })
                ]
              ),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.appSecret,
                    expression: "appSecret"
                  }
                ],
                staticClass: "form-control mb-2 col-8 element-hide",
                attrs: { type: "text", id: "wechat-appsecret-input" },
                domProps: { value: _vm.appSecret },
                on: {
                  keypress: function($event) {
                    return _vm.confirmChangeSecret($event)
                  },
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.appSecret = $event.target.value
                  }
                }
              })
            ])
          ])
        ])
      : _vm._e(),
    _vm.accountType == "fb" || _vm.accountType == "ig"
      ? _c("div", { attrs: { id: "facebook-block" } }, [
          _c("div", { staticClass: "row mt-4 my-2" }, [
            _c("div", { staticClass: "col-3 my-2" }, [_vm._v("app token")]),
            _c("div", { staticClass: "row col-9 my-2" }, [
              _c(
                "p",
                {
                  staticClass: "mb-2 col-7",
                  attrs: { id: "facebook-apptoken-title" }
                },
                [_vm._v(_vm._s(_vm.appToken))]
              ),
              _c(
                "span",
                {
                  attrs: { id: "facebook-apptoken-edit" },
                  on: {
                    click: function($event) {
                      return _vm.editToken()
                    }
                  }
                },
                [
                  _c("i", {
                    staticClass: "fas fa-pencil-alt cursor-pointer pl-1"
                  })
                ]
              ),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.appToken,
                    expression: "appToken"
                  }
                ],
                staticClass: "form-control mb-2 col-8 element-hide",
                attrs: { type: "text", id: "facebook-apptoken-input" },
                domProps: { value: _vm.appToken },
                on: {
                  keypress: function($event) {
                    return _vm.confirmChangeToken($event)
                  },
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.appToken = $event.target.value
                  }
                }
              })
            ])
          ]),
          _c("div", { staticClass: "row mt-4 my-2" }, [
            _c("div", { staticClass: "col-3 my-2" }, [_vm._v("app secret")]),
            _c("div", { staticClass: "row col-9 my-2" }, [
              _c(
                "p",
                {
                  staticClass: "mb-2 col-7",
                  attrs: { id: "facebook-appsecret-title" }
                },
                [_vm._v("\n          " + _vm._s(_vm.appSecret) + "\n        ")]
              ),
              _c(
                "span",
                {
                  attrs: { id: "facebook-appsecret-edit" },
                  on: {
                    click: function($event) {
                      return _vm.editSecret()
                    }
                  }
                },
                [
                  _c("i", {
                    staticClass: "fas fa-pencil-alt cursor-pointer pl-1"
                  })
                ]
              ),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.appSecret,
                    expression: "appSecret"
                  }
                ],
                staticClass: "form-control mb-2 col-8 element-hide",
                attrs: { type: "text", id: "facebook-appsecret-input" },
                domProps: { value: _vm.appSecret },
                on: {
                  keypress: function($event) {
                    return _vm.confirmChangeSecret($event)
                  },
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.appSecret = $event.target.value
                  }
                }
              })
            ])
          ]),
          _vm.accountType == "fb"
            ? _c("div", { staticClass: "row mt-4 my-2" }, [
                _c("div", { staticClass: "col-3 my-2" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("__FbStartMessage")) +
                      "\n      "
                  )
                ]),
                _c("div", { staticClass: "col-4 my-2" }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.fbProfile.get_started.scenarioId,
                          expression: "fbProfile.get_started.scenarioId"
                        }
                      ],
                      staticClass: "custom-select",
                      attrs: { id: "fb-stat-msg-scenario" },
                      on: {
                        change: [
                          function($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function(o) {
                                return o.selected
                              })
                              .map(function(o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.fbProfile.get_started,
                              "scenarioId",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                          function($event) {
                            return _vm.onFbStartScenarioChanged()
                          }
                        ]
                      }
                    },
                    [
                      _c(
                        "option",
                        { attrs: { value: "", disabled: "", selected: "" } },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("__NextScenariooptional")) +
                              "\n          "
                          )
                        ]
                      ),
                      _vm._l(_vm.allScenario, function(scenario, index) {
                        return _c(
                          "option",
                          {
                            key: 100 + index,
                            domProps: { value: scenario.scenario.scenarioId }
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(scenario.scenario.scenarioName) +
                                "\n          "
                            )
                          ]
                        )
                      })
                    ],
                    2
                  )
                ])
              ])
            : _vm._e(),
          _vm.accountType == "fb"
            ? _c("div", { staticClass: "row mt-4 my-2" }, [
                _c("div", { staticClass: "col-3 my-2" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("__FbGreetingMessage")) +
                      "\n      "
                  )
                ]),
                _c("div", { staticClass: "row col-9 my-2" }, [
                  _c(
                    "p",
                    {
                      staticClass: "mb-2 col-7",
                      attrs: { id: "facebook-greeting-title" }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.fbProfile.greeting.text) +
                          "\n        "
                      )
                    ]
                  ),
                  _c(
                    "span",
                    {
                      attrs: { id: "facebook-greeting-edit" },
                      on: {
                        click: function($event) {
                          return _vm.editFbGreetingMsg()
                        }
                      }
                    },
                    [
                      _c("i", {
                        staticClass: "fas fa-pencil-alt cursor-pointer pl-1"
                      })
                    ]
                  ),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.fbProfile.greeting.text,
                        expression: "fbProfile.greeting.text"
                      }
                    ],
                    staticClass: "form-control mb-2 col-8 element-hide",
                    attrs: { type: "text", id: "facebook-greeting-input" },
                    domProps: { value: _vm.fbProfile.greeting.text },
                    on: {
                      keypress: function($event) {
                        return _vm.confirmChangeFbGreetingMsg($event)
                      },
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.fbProfile.greeting,
                          "text",
                          $event.target.value
                        )
                      }
                    }
                  })
                ])
              ])
            : _vm._e()
        ])
      : _vm._e(),
    _c("div", { staticClass: "row mt-4 my-2" }, [
      _c("div", { staticClass: "col-3 my-2" }, [
        _vm._v(_vm._s(_vm.$t("__changeAccountImage")))
      ]),
      _c("div", { staticClass: "col-9 pb-4 my-1" }, [
        _c(
          "div",
          {
            staticClass: "border",
            staticStyle: { "min-height": "150px", "max-width": "75%" }
          },
          [
            _c("vue-dropzone", {
              ref: "myVueDropzone",
              attrs: { id: "dropzone", options: _vm.dropzoneOptions },
              on: {
                "vdropzone-success": _vm.showSuccess,
                "vdropzone-removed-file": _vm.removeFile
              }
            })
          ],
          1
        )
      ])
    ]),
    _c("div", { staticClass: "row mt-4 my-2" }, [
      _c("div", { staticClass: "col-3 my-2" }, [
        _vm._v(_vm._s(_vm.$t("__changeAccountName")))
      ]),
      _c("div", { staticClass: "row col-9 my-2" }, [
        _c(
          "p",
          { staticClass: "mb-2 col-9", attrs: { id: "accountName-title" } },
          [_vm._v(_vm._s(_vm.accountName))]
        ),
        _c(
          "span",
          {
            attrs: { id: "accountName-appsecret" },
            on: {
              click: function($event) {
                return _vm.editAccountName()
              }
            }
          },
          [_c("i", { staticClass: "fas fa-pencil-alt cursor-pointer pl-1" })]
        ),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.accountName,
              expression: "accountName"
            }
          ],
          staticClass: "form-control mb-2 col-9 element-hide",
          attrs: { type: "text", id: "accountName-input" },
          domProps: { value: _vm.accountName },
          on: {
            keypress: function($event) {
              return _vm.confirmChangeToken($event)
            },
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.accountName = $event.target.value
            }
          }
        })
      ])
    ]),
    _c("div", { staticClass: "row mt-4 my-2" }, [
      _c(
        "div",
        {
          staticClass: "col-3 my-2",
          attrs: { "data-translate": "_keepRecord.setting" }
        },
        [
          _vm._v(
            "\n      " + _vm._s(_vm.$t("__Keepsessionrecords")) + "\n      "
          ),
          _c("i", {
            staticClass: "fas fa-question-circle tips tooltip-test",
            attrs: {
              "data-toggle": "tooltip",
              "data-placement": "top",
              title: _vm.$t("__Sessionrecord")
            }
          })
        ]
      ),
      _c("div", { staticClass: "col-2 my-2" }, [
        _c("select", { staticClass: "custom-select" }, [
          _c("option", { attrs: { disabled: "" } }, [
            _vm._v(_vm._s(_vm.$t("__1day")))
          ]),
          _c("option", { attrs: { disabled: "" } }, [
            _vm._v(_vm._s(_vm.$t("__10days")))
          ]),
          _c("option", { attrs: { disabled: "" } }, [
            _vm._v(_vm._s(_vm.$t("__30days")))
          ]),
          _c("option", { attrs: { disabled: "" } }, [
            _vm._v(_vm._s(_vm.$t("__60days")))
          ]),
          _c("option", { attrs: { disabled: "" } }, [
            _vm._v(_vm._s(_vm.$t("__90days")))
          ]),
          _c("option", { attrs: { selected: "" } }, [
            _vm._v(_vm._s(_vm.$t("__180days")))
          ])
        ])
      ])
    ]),
    _c("div", { staticClass: "row mt-4 my-2" }, [
      _c(
        "div",
        { staticClass: "col-3 my-2", attrs: { "data-translate": "" } },
        [
          _c("span", { staticClass: "pt-2" }, [
            _vm._v(_vm._s(_vm.$t("__Deleteuserrelatedsettings")))
          ]),
          _c("i", {
            staticClass: "fas fa-question-circle tips tooltip-test",
            attrs: {
              "data-toggle": "tooltip",
              "data-placement": "top",
              title: _vm.$t("__Clearallusagerecordsthesystemwillonly")
            }
          })
        ]
      ),
      _c(
        "button",
        {
          staticClass: "btn btn-outline-danger px-4 ml-3",
          attrs: { "data-translate": "" },
          on: {
            click: function($event) {
              return _vm.deleteUserData()
            }
          }
        },
        [
          _vm._v(
            "\n      " +
              _vm._s(_vm.$t("__Deleteuserrelatedsettings")) +
              "\n    "
          )
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }