<template>
    <!-- modal start -->
    <div class="modal fade" id="auto-reply-comments-modal" tabindex="-1" role="dialog"
        aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
            <div class="modal-content tag-modal">
                <div class="modal-header">
                    <span class="modal-title" id="meta-util-title">
                        {{ "Util ID : " + editId }}
                    </span>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <!-- Loading spinner -->
                    <div v-if="isLoading" class="text-center">
                        <div class="spinner-border">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                    <!-- Post message start -->
                    <div v-if="!isLoading" class="container-fluid">
                        <div v-if="formInput.type == 'auto_reply_comments' || formInput.type == 'auto_reply_media'" class="row">
                            <div class="col-7">
                                <div>
                                    <label class="pt-3 mb-2"><span class="text-danger">*</span>{{ $t("__utilsName")
                                        }}</label>
                                    <input type="text" class="form-control" :placeholder="$t('__RichMenuText')"
                                        v-model="formInput.name" />
                                </div>
                                <div class="pt-2">
                                    <label class="pt-3 mb-2"><span class="text-danger">*</span>{{ $t("__workTime") }}</label>
                                    <div class="form-row">
                                        <div class="form-group col-6">
                                            <label>{{ $t("__DateTimeTriggerStart") }}</label>
                                            <datetime v-model="formInput.range.start" type="datetime"
                                                input-class="form-control"></datetime>
                                        </div>
                                        <div class="form-group col-6">
                                            <label>{{ $t("__DateTimeTriggerEnd") }}</label>
                                            <datetime v-model="formInput.range.end" type="datetime"
                                                input-class="form-control">
                                            </datetime>
                                        </div>
                                    </div>
                                </div>
                                <div class="pt-2">
                                    <label class="pt-3 mb-2"><span class="text-danger">*</span>{{ $t("__instagramPost") }}</label>
                                    <select class="form-control" v-model="selectedPost">
                                        <option value disabled>{{ $t("__neetSelectPost") }}</option>
                                        <option v-for="(item, index) in filteredPosts" :key="index" :value="item.data">
                                            <span v-if="formInput.type == 'auto_reply_comments' && item.data.caption">{{ item.data.caption }}</span>
                                            <span v-if="formInput.type == 'auto_reply_comments' && !item.data.caption">{{ $t("__instagramPost") }}</span>
                                            <span v-if="formInput.type == 'auto_reply_media' && item.data.caption">{{ item.data.caption }}</span>
                                            <span v-if="formInput.type == 'auto_reply_media' && !item.data.caption">{{ $t("__shortVideo") }}</span>
                                        </option>
                                    </select>
                                </div>
                                <div class="pt-2">
                                    <video :key="selectedPost.id" v-if="selectedPost && selectedPost.media_type === 'VIDEO'" controls>
                                        <source :src="selectedPost.media_url" type="video/mp4">
                                    </video>
                                    <img v-else-if="selectedPost && selectedPost.media_type === 'IMAGE'" :src="selectedPost.media_url" style="max-height: 60vh;">
                                </div>
                            </div>
                            <div class="col-3 ml-2">
                                <div>
                                    <label class="pt-3 mb-2">{{ $t("__defaultReply") }}</label>
                                    <div>
                                        <button type="button" class="btn btn-outline-secondary" style="width: 100%" v-if="!createDefaultMessage"
                                            data-translate="_create.meta-util" @click="createDefaultSetting()">
                                            {{ $t("__createDefaultReply") }}
                                        </button>
                                    </div>
                                    <div>
                                        <button type="button" class="btn btn-outline-secondary" style="width: 80%" v-if="createDefaultMessage" @click="editDefaultSetting()">
                                        {{ $t("__defaultReply") }}
                                        </button>
                                        <a href="javascript:void(0)" style="width: 20%" @click="deleteDefaultMessage()" v-if="createDefaultMessage">
                                            <i class="fas fa-trash text-danger mx-1"></i>
                                        </a>
                                    </div>
                                </div>
                                <div>
                                    <label class="pt-3 mb-2">{{ $t("__conditionReply") }}</label>
                                    <div>
                                        <button type="button" class="btn btn-outline-secondary" style="width: 100%"
                                            data-translate="_create.meta-util" @click="createConditionSetting()">
                                            {{ $t("__createConditionReply") }}
                                        </button>
                                    </div>
                                    <div v-for="(item,index) in multiCondition" :key="index" class="mt-2">
                                        <button type="button" class="btn btn-outline-secondary" style="width: 80%" v-if="multiCondition.length > 0" @click="editConditionSetting(item,index)">
                                        {{ $t("__conditionReply") + (index + 1) }}
                                        </button>
                                        <a href="javascript:void(0)" style="width: 20%" @click="deleteConditionMessage(index)" v-if="multiCondition.length > 0">
                                            <i class="fas fa-trash text-danger mx-1"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="formInput.type == 'short_link'" class="row">
                            <div class="col-7">
                                <label class="pt-3 mb-2"><span class="text-danger">*</span>{{ $t("__utilsName") }}</label>
                                <input type="text" class="form-control" :placeholder="$t('__RichMenuText')"
                                    v-model="formInput.name" />
                            </div>
                            <div class="col-7 pt-2">
                                <label class="pt-3 mb-2"><span class="text-danger">*</span>{{ $t("__workTime") }}</label>
                                <div class="form-row">
                                    <div class="form-group col-6">
                                        <label>{{ $t("__DateTimeTriggerStart") }}</label>
                                        <datetime v-model="formInput.range.start" type="datetime"
                                            input-class="form-control"></datetime>
                                    </div>
                                    <div class="form-group col-6">
                                        <label>{{ $t("__DateTimeTriggerEnd") }}</label>
                                        <datetime v-model="formInput.range.end" type="datetime"
                                            input-class="form-control">
                                        </datetime>
                                    </div>
                                </div>
                            </div>
                            <div class="col-7 pt-2">
                                <label class="pt-3 mb-2"><span class="text-danger">*</span>{{ $t("__startScrnario") }}</label>
                                <select class="form-control" v-model="formInput.scenarioId">
                                    <option value disabled selected>
                                        {{ $t("__NextScenariooptional") }}
                                    </option>
                                    <option :value="scenario.scenario.scenarioId"
                                        v-for="(scenario, index) in allScenario" :key="100 + index">
                                        {{ scenario.scenario.scenarioName }}
                                    </option>
                                </select>
                            </div>
                            <div class="col-7 pt-2">
                                <label class="pt-3 mb-2"><span class="text-danger">*</span>{{ $t("__generateShortLink") }}</label>
                                <span @click="onClickCopyShortLink(shortLink)">
                                    <i class="fas fa-copy cursor-pointer mx-2" />
                                </span>
                                <input type="text" class="form-control" v-model="shortLink"/>
                            </div>
                            <div class="col-7 pt-2">
                                <label class="pt-3 mb-2"><span class="text-danger">*</span>{{ $t("__generateQRCode") }}</label>
                                <qrcode-vue class="text-left" :value="formInput.url" size="200" level="H" v-model="shortLink"/>
                            </div>
                        </div>
                        <div v-if="formInput.type == 'auto_reply_live_mention'" class="row">
                            <div class="col-7">
                                <label class="pt-3 mb-2"><span class="text-danger">*</span>{{ $t("__utilsName") }}</label>
                                <input type="text" class="form-control" :placeholder="$t('__RichMenuText')"
                                    v-model="formInput.name" />
                            </div>
                            <div class="col-7 pt-2">
                                <label class="pt-3 mb-2"><span class="text-danger">*</span>{{ $t("__workTime") }}</label>
                                <div class="form-row">
                                    <div class="form-group col-6">
                                        <label>{{ $t("__DateTimeTriggerStart") }}</label>
                                        <datetime v-model="formInput.range.start" type="datetime"
                                            input-class="form-control"></datetime>
                                    </div>
                                    <div class="form-group col-6">
                                        <label>{{ $t("__DateTimeTriggerEnd") }}</label>
                                        <datetime v-model="formInput.range.end" type="datetime"
                                            input-class="form-control">
                                        </datetime>
                                    </div>
                                </div>
                            </div>
                            <div class="col-7 pt-2">
                                <label class="pt-3 mb-2"><span class="text-danger">*</span>{{ $t("__startScrnario") }}</label>
                                <select class="form-control" v-model="formInput.scenarioId">
                                    <option value disabled selected>
                                        {{ $t("__NextScenariooptional") }}
                                    </option>
                                    <option :value="scenario.scenario.scenarioId"
                                        v-for="(scenario, index) in allScenario" :key="100 + index">
                                        {{ scenario.scenario.scenarioName }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <!-- Post message end -->
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-outline-secondary" style="width: 100%"
                        data-translate="_create.meta-util" @click="submitMetaUtility()">
                        {{ modalData.modalBtn }}
                    </button>
                </div>
                <reply-content-setting-modal :adminId="adminId" :jwtToken="jwtToken" :accountId="accountId"
                    v-bind:modalData="modalData" :isDefault="isDefault" :allScenario="allScenario" :defaultMessage="defaultMessage" :conditionMessage="conditionMessage" :key="replyContentSettingModalKey" @sendDefaultMessageModal="handleDefaultMessageModal" @sendConditionMessageModal="handleConditionMessageModal" v-if="replyContentSettingModalKey">
                </reply-content-setting-modal>
            </div>
        </div>
    </div>
    <!-- modal end -->
</template>

<script>

import ReplyContentSettingModal from "./ReplyContentSettingModal.vue";
import QrcodeVue from "qrcode.vue";
import { Datetime } from "vue-datetime";
import { apiFunction } from "../Axios/functions";
export default {
    components: { QrcodeVue, Datetime, ReplyContentSettingModal },
    name: "auto-reply-comments-modal",
    props: {
        adminId: String,
        jwtToken: String,
        accountId: String,
        modalData: Object,
        allScenario: Array,
        editId: String,
        appToken: String,
        igAccountId: String,
        igPost: Array
    },
    data() {
        return {
            accountName: "",
            isLoading: false,
            replyContentSettingModalKey: "",
            isDefault: false,
            createDefaultMessage: false,
            shortLinkPrefix: "https://ig.me/m/",
            formInput: {
                name: "",
                type: "",
                range: {
                    start: "",
                    end: ""
                },
                enable: true,
                scenarioId: ""
            },
            defaultMessage: {
                useDefaultMessage: false,
                publicMessage: "",
                secretMessage: "",
            },
            conditionMessage: {
                useDefaultMessage: false,
                publicMessage: "",
                secretMessage: "",
                conditionFields: []
            },
            multiConditionIndex: 0,
            multiCondition: [],
            selectedPost: null,
            putBody: {
                adminId: "",
                accountId: "",
                utilId: "",
                name: "",
                type: "",
                enable: false,
                range: {
                    start: "",
                    end: ""
                },
                mediaId: "",
                default: "",
                scenarioId: "",
                multi_condition: []
            }
        };
    },
    mounted() {
        this.$watch(
            "modalData",
            (modalData) => {
                this.loadData();
            },
            { immediate: true }
        );
    },
    created() {
        
    },
    watch: {
        
    },
    computed: {
        shortLink() {
            return `${this.shortLinkPrefix}${this.accountName}?ref=${this.formInput.scenarioId}_${this.editId}`;
        },
        filteredPosts() {
            if (this.formInput.type === 'auto_reply_comments') {
                return this.igPost.filter(item => item.data.media_type == "IMAGE");
            } else if (this.formInput.type === 'auto_reply_media') {
                return this.igPost.filter(item => item.data.media_type == "VIDEO");
            };
        }
    },
    methods: {
        // Prepare page content
        async loadData() {
            try{
                this.formInput.name = this.modalData.name;
                this.formInput.type = this.modalData.type;
                this.formInput.range = this.modalData.range;
                this.formInput.enable = this.modalData.enable;
                this.formInput.scenarioId = this.modalData.scenarioId;
                this.selectedPost = this.modalData.mediaId;

                if(this.modalData.defaultMessage !== undefined){
                    this.createDefaultMessage = true;
                    this.defaultMessage = this.modalData.defaultMessage;
                }
                this.multiCondition = this.modalData.multiCondition;
                await apiFunction.getIGAccountName(this.appToken,this.igAccountId).then((data) => {
                    if(data.status === 200){
                        console.log(data)
                        this.accountName = data.data.username;
                    };
                });
                $("#auto-reply-comments-modal").modal("show");
            }catch(e){
                console.log(e);
                return this.$fire({
                    title: this.$t("__Addfailed"),
                    text: this.$t("__oopsSomethingwentwrong"),
                    type: "error",
                });
            }
            
        },
        handleDefaultMessageModal(value){
            this.defaultMessage.useDefaultMessage = value.useDefaultMessage;
            this.defaultMessage.publicMessage = value.publicMessage;
            this.defaultMessage.secretMessage = value.secretMessage;
        },
        handleConditionMessageModal(value){
            this.multiCondition[this.multiConditionIndex].useDefaultMessage = value.useDefaultMessage;
            this.multiCondition[this.multiConditionIndex].publicMessage = value.publicMessage;
            this.multiCondition[this.multiConditionIndex].secretMessage = value.secretMessage;
            this.multiCondition[this.multiConditionIndex].conditionFields = value.conditionFields;
        },
        // Create & edit Meta Utility
        async submitMetaUtility() {
            this.putBody.adminId = this.adminId;
            this.putBody.accountId = this.accountId;
            this.putBody.utilId = this.editId;
            this.putBody.name = this.formInput.name;
            this.putBody.type = this.formInput.type;
            this.putBody.enable = this.formInput.enable;
            this.putBody.range.start = this.formInput.range.start;
            this.putBody.range.end = this.formInput.range.end;
            this.putBody.default = this.defaultMessage;
            this.putBody.multi_condition = this.multiCondition;
            this.putBody.mediaId = this.selectedPost.id;
            this.putBody.scenarioId = this.formInput.scenarioId;

            //必要欄位validation
            switch(this.putBody.type){
                case 'short_link':
                    if(!this.putBody.scenarioId){
                        return this.$fire({
                            title: this.$t("__Addfailed"),
                            text: this.$t("__pleaseEnterScenario"),
                            type: "error",
                        });
                    }else if(!this.putBody.name){
                        return this.$fire({
                            title: this.$t("__Addfailed"),
                            text: this.$t("__pleaseEnterName"),
                            type: "error",
                        });
                    };
                    break;
                case 'auto_reply_live_mention':
                    if(!this.putBody.scenarioId){
                        return this.$fire({
                            title: this.$t("__Addfailed"),
                            text: this.$t("__pleaseEnterScenario"),
                            type: "error",
                        });
                    }else if(!this.putBody.name){
                        return this.$fire({
                            title: this.$t("__Addfailed"),
                            text: this.$t("__pleaseEnterName"),
                            type: "error",
                        });
                    };;
                    break;
                default:
                    if(this.putBody.default.useDefaultMessage === true && (!this.putBody.default.publicMessage || this.putBody.default.secretMessage === "selectedScenario" || !this.putBody.default.secretMessage)){
                        return this.$fire({
                            title: this.$t("__Addfailed"),
                            text: this.$t("__pleaseEnterDefaultField"),
                            type: "error",
                        });
                    }else if(!this.putBody.mediaId){
                        return this.$fire({
                            title: this.$t("__Addfailed"),
                            text: this.$t("__pleaseEnterMediaId"),
                            type: "error",
                        });
                    }
                    else if(this.putBody.multi_condition.length === 0 && (!this.putBody.default.publicMessage && (this.putBody.default.secretMessage === "selectedScenario" || !this.putBody.default.secretMessage))){
                        return this.$fire({
                            title: this.$t("__Addfailed"),
                            text: this.$t("__pleaseEnterDefaultField"),
                            type: "error",
                        });
                    }
                    else if(!this.putBody.default.publicMessage){
                        this.putBody.default.publicMessage = null;
                    };
                    
                    for(let i=0;i<this.putBody.multi_condition.length;i++){
                        if(this.putBody.multi_condition[i].useDefaultMessage === true && (!this.putBody.multi_condition[i].publicMessage || this.putBody.multi_condition[i].conditionFields.length == 0 || this.putBody.multi_condition[i].secretMessage === "selectedScenario" || !this.putBody.multi_condition[i].secretMessage)){
                            return this.$fire({
                                title: this.$t("__Addfailed"),
                                text: this.$t("__pleaseEnterConditionFields"),
                                type: "error",
                            });
                        }
                        else if(!this.putBody.multi_condition[i].publicMessage){
                            this.putBody.multi_condition[i].publicMessage = null;
                        }
                        for(let e=0;e<this.putBody.multi_condition[i].conditionFields.length;e++){
                            const item = this.putBody.multi_condition[i].conditionFields[e];
                            if(!item.value){
                                return this.$fire({
                                    title: this.$t("__Addfailed"),
                                    text: this.$t("__pleaseEnterConditionTrigger"),
                                    type: "error",
                                });
                            };
                        };
                    };
                    break;
                };
            
            await apiFunction.saveUtil(this.putBody, this.jwtToken).then((data) => {
                if(data.status === 200){
                    this.$fire({
                        title: this.$t("__addedsuccessfully"),
                        text: this.modalData.modalSuccessMsg,
                        type: "success",
                        timer: 1500,
                    }).then((e) => {
                        $("#auto-reply-comments-modal").modal("hide");
                        this.$router.go(0);
                    });
                }else{
                    $("#auto-reply-comments-modal").modal("hide");
                    this.$fire({
                        title: this.$t("__Addfailed"),
                        text: this.$t("__oopsSomethingwentwrong"),
                        type: "error",
                    });
                };
            });
        },
        //open default reply modal
        createDefaultSetting() {
            this.isDefault = true;
            this.replyContentSettingModalKey = Date.now();
            this.createDefaultMessage = true;
        },
        //open condition reply modal
        createConditionSetting() {
            this.isDefault = false;
            if(this.multiCondition.length > 0){
                this.multiConditionIndex = this.multiCondition.length;
            };
            this.conditionMessage.useDefaultMessage = false;
            this.conditionMessage.publicMessage = "";
            this.conditionMessage.secretMessage = "";
            this.conditionMessage.conditionFields = [];
            this.multiCondition.push({
                "useDefaultMessage": false,
                "publicMessage":  "",
                "secretMessage":  "",
                "conditionFields": []
            });
            this.replyContentSettingModalKey = Date.now();
        },
        //edit the default reply modal
        editDefaultSetting() {
            this.isDefault = true;
            this.replyContentSettingModalKey = Date.now();
            this.createDefaultMessage = true;
        },
        //edit the condition reply modal
        editConditionSetting(item,index) {
            this.isDefault = false;
            this.multiConditionIndex = index;
            this.conditionMessage.useDefaultMessage = item.useDefaultMessage;
            this.conditionMessage.publicMessage = item.publicMessage;
            this.conditionMessage.secretMessage = item.secretMessage;
            this.conditionMessage.conditionFields = item.conditionFields;
            this.replyContentSettingModalKey = Date.now();
            
        },
        onClickCopyShortLink(text) {
            navigator.clipboard.writeText(text).then(() => {
                this.$fire({
                text: this.$t("__CopiedToClipboard"),
                type: "success",
                });
            });
        },
        deleteDefaultMessage(){
            this.$fire({
                title: this.$t("__Areyousureyouwanttodeleteit"),
                text: this.$t("__Thesystemwillnotbeabletoretrieveyourdata"),
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: this.$t("__YesIwanttodelete"),
                cancelButtonText: this.$t("__cancel"),
            }).then((result) => {
                if (result.value) {
                    this.createDefaultMessage = false;
                    this.defaultMessage = {};
                };
            });
        },
        deleteConditionMessage(index){
            this.$fire({
                title: this.$t("__Areyousureyouwanttodeleteit"),
                text: this.$t("__Thesystemwillnotbeabletoretrieveyourdata"),
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: this.$t("__YesIwanttodelete"),
                cancelButtonText: this.$t("__cancel"),
            }).then((result) => {
                if (result.value) {
                    this.multiCondition.splice(index, 1);
                };
            });
        }
    },
};
</script>