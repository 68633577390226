var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("card", { staticClass: "main-content" }, [
    _c("div", { attrs: { id: "content-wrapper" } }, [
      _c(
        "div",
        { staticClass: "border border-success rounded px-4 py-4 mb-3" },
        [
          _c("h5", [
            _c("u", [
              _c(
                "a",
                { attrs: { href: "#/story", "data-translate": "_node.top" } },
                [_vm._v(_vm._s(_vm.$t("__node")))]
              )
            ])
          ]),
          _c("span", { attrs: { "data-translate": "_currentNode.top" } }, [
            _vm._v(_vm._s(_vm.$t("__Numberofexistingnodes")))
          ]),
          _c("span", [_vm._v(": " + _vm._s(_vm.currentNodes))])
        ]
      ),
      _c(
        "div",
        { staticClass: "border border-success rounded px-4 py-4 mb-3" },
        [
          _c("h5", [
            _c("u", [
              _vm.isNameFollower == true
                ? _c(
                    "a",
                    {
                      attrs: {
                        href: "#/segment/userList",
                        "data-translate": "_follower.top"
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("__Followers")))]
                  )
                : _vm._e(),
              _vm.isNameFollower == false
                ? _c(
                    "a",
                    {
                      attrs: {
                        href: "#/segment/userList",
                        "data-translate": "_follower.top"
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("__Triggerers")))]
                  )
                : _vm._e()
            ])
          ]),
          _c("span", { attrs: { "data-translate": "" } }, [
            _vm._v(_vm._s(_vm.$t("__Trackusersnow")))
          ]),
          _c("span", [_vm._v(": " + _vm._s(_vm.currentFollowers))])
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }