import axios from 'axios';

export const apiFunction = {
  // Show scenario in scenario page
  // ***required fields
  // **
  // "adminId": "string",
  // "accountId": "string"
  // "scenarioId": "string" ( if with scenarioId get only one specific scenario )
  // **
  // ***
  getScenario: function (formData, jwtToken) {
    return axios({
      method: "POST",
      url: process.env.VUE_APP_API_ENDPOINT + "getScenario",
      data: formData,
      headers: {
          Authorization: `Bearer ${jwtToken}`
      },
    }).then(res => {
      return res;
    }).catch(e => {
      console.log(e.response);
      return {error: e.response ? e.response : "null"}
    });
  },

  // Save scenario in scenario page
  // ***required fields
  // **
  // "adminId": "string",
  // "accountId": "string"
  // "scenarioId": "string"
  // **
  // ***
  saveScenario: function (formData, jwtToken) {
    return axios({
      method: "POST",
      url: process.env.VUE_APP_API_ENDPOINT + "saveScenario",
      data: formData,
      headers: {
          Authorization: `Bearer ${jwtToken}`
      },
    }).then(res => {
      return res;
    }).catch(e => {
      console.log(e.response);
      return {error: e.response ? e.response : "null"}
    });
  },

  // Delete scenario in scenario page
  // ***required fields
  // **
  // "adminId": "string",
  // "accountId": "string"
  // "scenarioId": "string"
  // **
  // ***
  deleteScenario: function (formData, jwtToken) {
    return axios({
      method: "POST",
      url: process.env.VUE_APP_API_ENDPOINT + "deleteScenario",
      data: formData,
      headers: {
        Authorization: `Bearer ${jwtToken}`
      },
    }).then(res => {
      return res;
    }).catch(e => {
      console.log(e.response);
      return {error: e.response ? e.response : "null"}
    });
  },

  // Get tags in scenario page
  // ***required fields
  // **
  // "adminId": "string",
  // "accountId": "string"
  // **
  // ***
  getTag: function (formData, jwtToken) {
    return axios({
      method: "POST",
      url: process.env.VUE_APP_API_ENDPOINT + "getTag",
      data: formData,
      headers: {
        Authorization: `Bearer ${jwtToken}`
      },
    }).then(res => {
      return res;
    }).catch(e => {
      console.log(e.response);
      return {error: e.response ? e.response : "null"}
    });
  },

  // Get variables in scenario page
  // ***required fields
  // **
  // "adminId": "string",
  // "accountId": "string"
  // **
  // ***
  getVariable: function (formData, jwtToken) {
    return axios({
      method: "POST",
      url: process.env.VUE_APP_API_ENDPOINT + "getVariable",
      data: formData,
      headers: {
        Authorization: `Bearer ${jwtToken}`
      },
    }).then(res => {
      return res;
    }).catch(e => {
      console.log(e.response);
      return {error: e.response ? e.response : "null"}
    });
  },

  // Save variables
  // ***required fields
  // **
  // "adminId": "string",
  // "accountId": "string",
  // "accountVariable": ["string"]
  // **
  // ***
  saveVariable: function (formData, jwtToken) {
    return axios({
      method: "POST",
      url: process.env.VUE_APP_API_ENDPOINT + "saveVariable",
      data: formData,
      headers: {
        Authorization: `Bearer ${jwtToken}`
      },
    }).then(res => {
      return res;
    }).catch(e => {
      console.log(e.response);
      return {error: e.response ? e.response : "null"}
    });
  },

  // Delete variables
  // ***required fields
  // **
  // "adminId": "string",
  // "accountId": "string",
  // "accountVariable": ["string"]
  // **
  // ***
  deleteVariable: function (formData, jwtToken) {
    return axios({
      method: "POST",
      url: process.env.VUE_APP_API_ENDPOINT + "deleteVariable",
      data: formData,
      headers: {
        Authorization: `Bearer ${jwtToken}`
      },
    }).then(res => {
      return res;
    }).catch(e => {
      console.log(e.response);
      return {error: e.response ? e.response : "null"}
    });
  },

  // Get user segment for broadcast target
  // ***required fields
  // **
  // "adminId": "string"
  // "accountId": "string"
  // **
  // ***
  getUserSegment: function (formData, jwtToken) {
    return axios({
      method: "POST",
      url: process.env.VUE_APP_API_ENDPOINT + "getUserSegment",
      data: formData,
      headers: {
        Authorization: `Bearer ${jwtToken}`
      },
    }).then(res => {
      return res;
    }).catch(e => {
      console.log(e);
      return {error: e.response ? e.response : "null"}
    });
  },

  // Get CROS error code list
  // ***required fields
  // **
  // "adminId": "string"
  // "accountId": "string"
  // **
  // ***
  getCrosError: function (formData, jwtToken) {
    return axios({
      method: "POST",
      url: process.env.VUE_APP_API_ENDPOINT + "getCrosError",
      data: formData,
      headers: {
        Authorization: `Bearer ${jwtToken}`
      },
    }).then(res => {
      return res;
    }).catch(e => {
      console.log(e);
      return {error: e.response ? e.response : "null"}
    });
  },

  // Save var Form
  // ***required fields
  // **
  // "adminId": "string",
  // "accountId": "string",
  // "name": "string",
  // "fields" ["string"]
  // **
  // ***
  saveForm: function (formData, jwtToken) {
    return axios({
      method: "POST",
      url: process.env.VUE_APP_API_ENDPOINT + "saveForm",
      data: formData,
      headers: {
        Authorization: `Bearer ${jwtToken}`
      },
    }).then(res => {
      return res;
    }).catch(e => {
      console.log(e.response);
      return {error: e.response ? e.response : "null"}
    });
  },

  // Delete var Form
  // ***required fields
  // **
  // "adminId": "string",
  // "accountId": "string",
  // "formId" "string"
  // **
  // ***
  deleteForm: function (formData, jwtToken) {
    return axios({
      method: "POST",
      url: process.env.VUE_APP_API_ENDPOINT + "deleteForm",
      data: formData,
      headers: {
        Authorization: `Bearer ${jwtToken}`
      },
    }).then(res => {
      return res;
    }).catch(e => {
      console.log(e.response);
      return {error: e.response ? e.response : "null"}
    });
  },

  // Get var Form
  // ***required fields
  // **
  // "adminId": "string",
  // "accountId": "string"
  // **
  // ***
  getForm: function (formData, jwtToken) {
    return axios({
      method: "POST",
      url: process.env.VUE_APP_API_ENDPOINT + "getForm",
      data: formData,
      headers: {
        Authorization: `Bearer ${jwtToken}`
      },
    }).then(res => {
      return res;
    }).catch(e => {
      console.log(e.response);
      return {error: e.response ? e.response : "null"}
    });
  },

  // Get user icon
  // ***required fields
  // **
  // "adminId": "string"
  // "accountId": "string"
  // ***
  getIconUrl: function (formData, jwtToken) {
    return axios({
      method: "POST",
      url: process.env.VUE_APP_API_ENDPOINT + "getIconUrl",
      data: formData,
      headers: {
        Authorization: `Bearer ${jwtToken}`
      },
    }).then(res => {
      return res;
    }).catch(e => {
      console.log(e);
      return { error: e.response ? e.response : "null" }
    });
  },

};
