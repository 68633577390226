var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal fade",
      attrs: { id: "qr-code-modal", role: "dialog", "aria-hidden": "true" }
    },
    [
      _c(
        "div",
        {
          staticClass: "modal-dialog modal-dialog-centered",
          attrs: { role: "document" }
        },
        [
          _c("div", { staticClass: "modal-content" }, [
            _vm._m(0),
            _c(
              "div",
              { staticClass: "modal-body" },
              [
                _c("qrcode-vue", {
                  staticClass: "text-center",
                  attrs: {
                    value: _vm.modalData.liffUrl,
                    size: "200",
                    level: "H"
                  }
                }),
                _c("div", { staticClass: "my-2" }, [
                  _c("label", [_vm._v(" LIFF URL")]),
                  _vm.isSupportClipboardApi()
                    ? _c(
                        "span",
                        {
                          on: {
                            click: function($event) {
                              return _vm.onClickCopyText(_vm.modalData.liffUrl)
                            }
                          }
                        },
                        [
                          _c("i", {
                            staticClass: "fas fa-copy cursor-pointer mx-2"
                          })
                        ]
                      )
                    : _vm._e(),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.modalData.liffUrl,
                        expression: "modalData.liffUrl"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: { disabled: "" },
                    domProps: { value: _vm.modalData.liffUrl },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.modalData, "liffUrl", $event.target.value)
                      }
                    }
                  })
                ]),
                _c("div", { staticClass: "my-2" }, [
                  _c("label", [
                    _vm._v(" " + _vm._s(_vm.$t("__jumpToSpecificScenario")))
                  ]),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.modalData.scenarioId,
                          expression: "modalData.scenarioId"
                        }
                      ],
                      staticClass: "form-control",
                      on: {
                        change: function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.modalData,
                            "scenarioId",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        }
                      }
                    },
                    [
                      _c(
                        "option",
                        { attrs: { value: "", disabled: "", selected: "" } },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("__NextScenariooptional")) +
                              "\n            "
                          )
                        ]
                      ),
                      _vm._l(_vm.allScenario, function(scenario, index) {
                        return _c(
                          "option",
                          {
                            key: 100 + index,
                            domProps: { value: scenario.scenario.scenarioId }
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(scenario.scenario.scenarioName) +
                                "\n            "
                            )
                          ]
                        )
                      })
                    ],
                    2
                  )
                ])
              ],
              1
            ),
            _c("div", { staticClass: "modal-footer" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-outline-secondary",
                  staticStyle: { width: "100%" },
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.submitSegment()
                    }
                  }
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(this.$t("__determine")) +
                      "\n        "
                  )
                ]
              )
            ])
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-header" }, [
      _c("h5", { staticClass: "modal-title" }, [_vm._v("LIFF LINK")]),
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            type: "button",
            "data-dismiss": "modal",
            "aria-label": "Close"
          }
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }