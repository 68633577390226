import { render, staticRenderFns } from "./UserList.vue?vue&type=template&id=0f6bc4a9&"
import script from "./UserList.vue?vue&type=script&lang=js&"
export * from "./UserList.vue?vue&type=script&lang=js&"
import style0 from "./UserList.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src366474742/src/bitbucket.org/atsuboi/chatbot/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0f6bc4a9')) {
      api.createRecord('0f6bc4a9', component.options)
    } else {
      api.reload('0f6bc4a9', component.options)
    }
    module.hot.accept("./UserList.vue?vue&type=template&id=0f6bc4a9&", function () {
      api.rerender('0f6bc4a9', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/Segment/UserList.vue"
export default component.exports