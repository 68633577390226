<template>
    <!-- modal start -->
    <div class="modal fade" id="create-meta-util-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true">
        <div class="modal-dialog modal-xl modal-dialog-centered justify-content-center" role="document">
            <div class="modal-content tag-modal col-6">
                <div class="modal-header">
                    <p class="modal-title text-bold" id="meta-util-title">
                        {{ formInput.name }}
                    </p>
                    <span id="meta-util-edit" @click="editName()">
                        <i class="fas fa-pencil-alt cursor-pointer pl-1"></i>
                    </span>
                    <input type="text" class="form-control element-hide" id="meta-util-input" v-model="formInput.name"
                        @keypress="confirmChangeName($event)" />
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <!-- Loading spinner -->
                    <div v-if="isLoading" class="text-center">
                        <div class="spinner-border">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                    <div v-if="!isLoading" class="container-fluid">
                        <label class="col-10">{{ $t("__utilsType") }}</label>
                        <select class="col-10 form-control" v-model="formInput.type">
                            <option value disabled>{{ $t("__needTypeName") }}</option>
                            <option v-for="(item,index) in utiloptionData" :key="index" :value="item.type">
                                <span v-if="item.type === 'auto_reply_comments'">{{ $t("__autoReplyComments") }}</span>
                                <span v-if="item.type === 'short_link'">{{ $t("__shortLink") }}</span>
                                <span v-if="item.type === 'auto_reply_live_mention'">{{ $t("__autoReplyLiveMention") }}</span>
                                <span v-if="item.type === 'auto_reply_media'">{{ $t("__autoReplyMedia") }}</span>
                            </option>
                        </select>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-outline-secondary" style="width: 100%"
                        data-translate="_create.meta-util" @click="submitMetaUtility()">
                        {{ modalData.modalBtn }}
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!-- modal end -->
</template>
  
<script>
import { apiFunction } from "../Axios/functions";
export default {
    components: {},
    name: "create-meta-util-modal",
    props: {
        adminId: String,
        jwtToken: String,
        accountId: String,
        modalData: Object,
        utiloptionData: Array,
        editId: {
            type: String,
            // default: "",
        },
    },
    data() {
        return {
            isLoading: false,
            formInput: {
                id: "",
                name: "",
                type: "",
                range: {
                    start: "",
                    end: ""
                },
                enable: true,
            },
        };
    },
    mounted() {
        this.$watch(
            "modalData",
            (modalData) => {
                this.loadData();
            },
            { immediate: true }
        );
    },
    created() {

    },
    methods: {
        // Prepare page content
        loadData() {
            this.formInput.name = this.modalData.name;
            this.formInput.type = this.modalData.type;
            this.formInput.range = this.modalData.range;
            this.formInput.enable = this.modalData.enable;
            $("#create-meta-util-modal").modal("show");
        },
        // title edit
        editName() {
            $("#meta-util-title").hide();
            $("#meta-util-input").show();
            $("#meta-util-edit").hide();
        },
        // title submit
        confirmChangeName($event) {
            if ($event.which == 13) {
                $("#meta-util-title").show();
                $("#meta-util-input").hide();
                $("#meta-util-edit").show();
                return false;
            }
        },
        // Create & edit Meta Utility
        async submitMetaUtility() {
            const currentDate = new Date();
            const utilEndDate = new Date(currentDate);
            utilEndDate.setDate(utilEndDate.getDate() + 7);
            const currentISOString = currentDate.toISOString();
            const utilEndDateISOString = utilEndDate.toISOString();

            let utilObject = {};

            this.isLoading = true;
            if(!this.formInput.type){
                $("#create-meta-util-modal").modal("hide");
                return this.$fire({
                    title: this.$t("__Addfailed"),
                    text: this.$t("__needTypeName"),
                    type: "error",
                });
            };

            if(this.editId == "0001"){
                utilObject = {
                    adminId: this.adminId,
                    accountId: this.accountId,
                    name: this.formInput.name, 
                    type: this.formInput.type, 
                    enable: false, 
                    range: {
                        start: currentISOString, 
                        end: utilEndDateISOString
                    }
                };
            }else{
                utilObject = {
                    adminId: this.adminId,
                    accountId: this.accountId,
                    utilId: this.editId,
                    name: this.formInput.name, 
                    type: this.formInput.type, 
                    enable: this.modalData.enable, 
                    range: this.modalData.range, 
                    mediaId: this.modalData.mediaId.id,
                    scenarioId: this.modalData.scenarioId,
                    default: this.modalData.defaultMessage,
                    multi_condition: this.modalData.multiCondition,
                };
            }
            
            await apiFunction.saveUtil(utilObject, this.jwtToken).then((data) => {
                if(data.status === 200){
                    this.$fire({
                        title: this.$t("__addedsuccessfully"),
                        text: this.modalData.modalSuccessMsg,
                        type: "success",
                        timer: 1500,
                    }).then((e) => {
                        $("#auto-reply-comments-modal").modal("hide");
                        this.$router.go(0);
                    });
                }else{
                    $("#auto-reply-comments-modal").modal("hide");
                    this.$fire({
                        title: this.$t("__Addfailed"),
                        text: this.$t("__oopsSomethingwentwrong"),
                        type: "error",
                    });
                };
            });
            $("#create-meta-util-modal").modal("hide");
            this.isLoading = false;
        },
    },
};
</script>
  