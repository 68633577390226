<template>
  <table class="table" :class="tableClass">
    <thead class="thead-dark">
      <th>#</th>
      <th>{{this.$t("__menuId")}}</th>
      <th style="cursor: pointer;" @click="onClickMenuNameColumnHeader">
        {{this.$t("__menuName")}} {{ getSortOrderLabel(menuNameColumnSortState) }}
      </th>
      <th>{{ this.$t("__workTime") }}</th>
      <th>{{this.$t("__segment")}}</th>
      <th></th>
    </thead>
    <tbody>
      <tr v-for="(item, index) in data" :key="index">
        <td>{{ index + 1 }}</td>
        <td>{{ item.id }}</td>
        <td>{{ item.name }}</td>
        <td>{{ toDateString(item.start_time) }} ~ {{ toDateString(item.end_time) }}</td>
        <td>{{ item.segmentName }}</td>
        <td style="width: 80px">
          <a
            href="javascript:void(0)"
            class="editStoryIcon"
            @click="editRichMenu(item)"
          >
            <i class="fas fa-edit mx-1"></i>
          </a>
          <a href="javascript:void(0)" @click="deleteRichMenu(item, index)">
            <i class="fas fa-trash text-danger mx-1"></i>
          </a>
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script>
import { Utility } from "../../../../src/utility";
export default {
  name: "rich-menu-table",
  props: {
    data: Array,
    type: {
      type: String, // striped | hover
      default: "striped",
    },
  },
  data() {
    return {
      // テーブルのソート状態（none, asc, desc）
      menuNameColumnSortState: 'none',
    }
  },
  computed: {
    tableClass() {
      return `table-${this.type}`;
    },
  },
  methods: {
    editRichMenu(richMenu) {
      this.$emit("editRichMenu", richMenu);
    },
    deleteRichMenu(richMenu, index) {
      this.$emit("deleteRichMenu", richMenu, index);
    },
    getSortOrderLabel(order) {
      if (order == 'none') return '';
      return order == 'asc' ? '▲' : '▼';
    },
    onClickMenuNameColumnHeader() {
      this.menuNameColumnSortState = (this.menuNameColumnSortState == 'asc' ? 'desc' : 'asc');
      this.sortTableByMenuNameColumn();
    },
    sortTableByMenuNameColumn() {
      if (this.menuNameColumnSortState == 'none') return;
      const ascFunc = (a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      }
      const descFunc = (a, b) => {
        if (a.name > b.name) return -1;
        if (a.name < b.name) return 1;
        return 0;
      }
      this.data.sort(this.menuNameColumnSortState == 'asc' ? ascFunc : descFunc);
    },
    toDateString(datetime) {
      return Utility.toDateString(datetime);
    },
  },
};
</script>
<style>
</style>
