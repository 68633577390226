<template>
    <!-- modal start -->
    <div class="modal fade" id="reply-content-setting-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content tag-modal">
                <div class="modal-header">
                    <p class="modal-title text-bold" id="meta-util-title" v-if="isDefault">{{ $t("__createDefaultReply") }}</p>
                    <p class="modal-title text-bold" id="meta-util-title" v-if="!isDefault">{{ $t("__createConditionReply") }}</p>
                    <button type="button" class="close" aria-label="Close"  @click="closeModal()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <!-- Loading spinner -->
                    <div v-if="isLoading" class="text-center">
                        <div class="spinner-border">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>

                    <div v-if="!isLoading" class="col-12">
                        <div v-if="!isDefault">
                            <div class="">
                                <div>
                                    <input type="checkbox" id="use-reply-template" v-model="editConditionMessage.useDefaultMessage">
                                    <label class="ml-2" for="use-reply-template">{{ $t("__useAutoReply") }}</label>
                                </div>
                            </div>
                            <div>
                                <button type="button" class="btn btn-outline-secondary mt-2 col-4" @click="addCondition()">
                                    {{ $t("__addConditionField") }}
                                </button>
                            </div>
                            <div style="display: flex;border: 1.5px solid #6c757d ;border-radius: 10px;margin-top: 2%;padding-bottom: 2%;" v-for="(e,index) in editConditionMessage.conditionFields" :key="index">
                                <div class="col-6">
                                    <label class="pt-3 mb-2">{{ $t("__setCondition") }}</label>
                                    <select class="form-control" v-model="e.selectedCondition">
                                        <option value="messageContent">{{ $t("__messageContent") }}</option>
                                        <option value="taggedCount">{{ $t("__friendsCount") }}</option>
                                    </select>
                                </div>
                                <div class="col-6">
                                    <label class="pt-3 mb-2">{{ $t("__conditionContent") }}</label>
                                    <div v-if="e.selectedCondition === 'messageContent'">
                                        <select class="form-control" v-model="e.selectedConditionType">
                                            <option v-for="(item,index) in keywordCondition" :key="index" :value="item.type">{{ item.name }}</option>
                                        </select>
                                        <input class="form-control mt-1" type="text" v-model="e.value">
                                    </div>
                                    <div v-if="e.selectedCondition === 'taggedCount'">
                                        <select class="form-control" v-model="e.selectedConditionType">
                                            <option :value="'>='">≥</option>
                                        </select>
                                        <input class="form-control mt-1" type="text" v-model="e.value">
                                    </div>
                                </div>
                                <a href="javascript:void(0)"  @click="deleteConditionField(index)">
                                    <i class="fas fa-trash text-danger mx-1"></i>
                                </a>
                            </div>
                            <div class="">
                                <label class="pt-3 mb-2 col-6">{{ $t("__publicMessage") }}</label>
                                <textarea id="" cols="30" rows="10" v-model="editConditionMessage.publicMessage"></textarea>
                            </div>
                            <div class="">
                                <label class="pt-3 mb-2 col-6">{{ $t("__secretMessage") }}</label>
                                <select name="" id="" class="form-control" v-model="editConditionMessage.secretMessage">
                                    <option value="selectedScenario">{{ $t("__selectedScenario") }}</option>
                                    <option :value="item.id" v-for="(item,index) in allScenario" :key="index">{{ item.scenario.scenarioName }}</option>
                                </select>
                            </div>
                        </div>
                        <div v-if="isDefault">
                            <div class="">
                                <div>
                                    <input type="checkbox" id="use-reply-template" v-model="editDefaultMessage.useDefaultMessage">
                                    <label class="ml-2" for="use-reply-template">{{ $t("__useAutoReply") }}</label>
                                </div>
                            </div>
                            <div class="">
                                <label class="pt-3 mb-2 col-6">{{ $t("__publicMessage") }}</label>
                                <textarea id="" cols="30" rows="10" v-model="editDefaultMessage.publicMessage"></textarea>
                            </div>
                            <div class="">
                                <label class="pt-3 mb-2 col-6">{{ $t("__secretMessage") }}</label>
                                <select name="" id="" class="form-control" v-model="editDefaultMessage.secretMessage">
                                    <option value="selectedScenario">{{ $t("__selectedScenario") }}</option>
                                    <option :value="item.id" v-for="(item,index) in allScenario" :key="index">{{ item.scenario.scenarioName }}</option>
                                </select>
                            </div>
                        </div>
                        
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-outline-secondary" style="width: 100%"
                        data-translate="_create.meta-util" @click="submitReplySetting()">
                        {{ $t("__submit") }}
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!-- modal end -->
</template>
  
<script>

export default {
    components: {},
    name: "reply-content-setting-modal",
    props: {
        adminId: String,
        jwtToken: String,
        accountId: String,
        modalData: Object,
        isDefault: Boolean,
        allScenario: Array,
        conditionMessage: Object,
        defaultMessage: Object,
    },
    data() {
        return {
            isLoading: false,
            editDefaultMessage: {
                useDefaultMessage: false,
                publicMessage: "",
                secretMessage: "",
            },
            editConditionMessage: {
                useDefaultMessage: false,
                publicMessage: "",
                secretMessage: "",
                conditionFields: [],
            },
            keywordCondition: [
                {
                    type: "exactlyMatch",
                    name: this.$t("__exactlyMatch")
                },
                {
                    type: "fuzzyMatch",
                    name: this.$t("__fuzzyMatch")
                }
            ],
        };
    },
    mounted() {
        this.$watch(
            "modalData",
            (modalData) => {
                this.loadData();
            },
            { immediate: true }
        );
    },
    created() {

    },
    methods: {
        // Prepare page content
        loadData() {
            this.editDefaultMessage.useDefaultMessage = this.defaultMessage.useDefaultMessage;
            this.editDefaultMessage.publicMessage = this.defaultMessage.publicMessage;
            this.editDefaultMessage.secretMessage = this.defaultMessage.secretMessage;
        
            this.editConditionMessage.useDefaultMessage = this.conditionMessage.useDefaultMessage;
            this.editConditionMessage.publicMessage = this.conditionMessage.publicMessage;
            this.editConditionMessage.secretMessage = this.conditionMessage.secretMessage;
            this.editConditionMessage.conditionFields = this.conditionMessage.conditionFields;
            $("#reply-content-setting-modal").modal("show");
        },
        closeModal() {
            $("#reply-content-setting-modal").modal("hide");
        },
        //created condition field
        addCondition(){
            this.editConditionMessage.conditionFields.push({
                selectedCondition: 'messageContent',
                selectedConditionType: "exactlyMatch",
                value: ""
            });
        },
        // Create & edit Meta Utility
        submitReplySetting() {
            if(this.isDefault){
                this.$emit("sendDefaultMessageModal", this.editDefaultMessage);
            }else{
                this.$emit("sendConditionMessageModal", this.editConditionMessage);
            };
            this.$nextTick(() => {
                $("#reply-content-setting-modal").modal("hide");
            });
        },
        //delete created condition field
        deleteConditionField(index){
            this.$fire({
                title: this.$t("__Areyousureyouwanttodeleteit"),
                text: this.$t("__Thesystemwillnotbeabletoretrieveyourdata"),
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: this.$t("__YesIwanttodelete"),
                cancelButtonText: this.$t("__cancel"),
            }).then((result) => {
                if (result.value) {
                    this.editConditionMessage.conditionFields.splice(index, 1);
                };
            });
        }
    },
};
</script>
