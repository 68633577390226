import CreateUserIconModal from "./CreateUserIcon.vue";


let modals = {
    CreateUserIconModal
}

export default modals;

export {
    CreateUserIconModal
}
