<template>
  <nav class="navbar navbar-expand-lg navbar-light">
    <div class="container-fluid">
      <div class="navbar-brand" href="javascript:void(0)">{{ routeName }}</div>
      <button
        class="navbar-toggler navbar-burger"
        type="button"
        @click="toggleSidebar"
        :aria-expanded="$sidebar.showSidebar"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-bar"></span>
        <span class="navbar-toggler-bar"></span>
        <span class="navbar-toggler-bar"></span>
      </button>
      <div class="collapse navbar-collapse">
        <div class="ml-auto mr-0">
          <a
            href="javascript:void(0)"
            class="nav-item ml-5"
            @click="switchJa()"
            v-if="chineselang == true"
            >日本語に切り替える</a
          >
          <a
            href="javascript:void(0)"
            class="nav-item ml-2"
            @click="switchTw()"
            v-if="chineselang == false"
            >切換中文</a
          >
        </div>

        <ul class="navbar-nav">
          <li class="nav-item">
            <a
              class="nav-link"
              href="#"
              @click="logOut()"
              v-if="isAdmin && !isAccToken()"
              >{{ $t("__Signout") }}</a
            >
          </li>
          <li class="nav-item dropdown" :key="renderKey">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdownMenuLink"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              v-if="isLogIn()"
            >
              <img :src="profileImg" width="25px" />
              {{ accountName }}
            </a>
            <div
              class="dropdown-menu"
              aria-labelledby="navbarDropdownMenuLink"
              v-if="isLogIn()"
            >
              <a class="dropdown-item" href="#" @click="changeAccount()">{{
                $t("__Switchaccount")
              }}</a>
              <a class="dropdown-item" href="#" @click="logOut()">{{
                $t("__Signout")
              }}</a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
import i18n from "@/i18n";
import { Hub } from "@aws-amplify/core";
import { richmenu } from "../../pages/RichMenu/RichMenu.vue";

const switchLang = (newLang) => {
  i18n.locale = newLang;
  localStorage.setItem("locale", newLang);
};

/**
 * ブラウザで設定されている言語コードを返す
 * 言語コードはIETFのBCP47に準拠したコードで表現される
 */
const getBrowserLanguage = () => {
  return (
    (window.navigator.languages && window.navigator.languages[0]) ||
    window.navigator.language ||
    window.navigator.userLanguage ||
    window.navigator.browserLanguage
  );
};

export default {
  components: {
    richmenu,
  },
  computed: {
    routeName() {
      const { name } = this.$route;
      switch (name) {
        case "dashboard":
          return this.$t("__home");
        case "story":
        case "nodes":
          return this.$t("__dialogsetup");
        case "richMenu":
          return this.$t("__richMenu");
        case "tagList":
          return this.$t("__tagList");
        case "segment":
        case "userList":
          return this.$t("__segment");
        case "pushMessage":
        case "history":
          return this.$t("__pushMessage");
        case "data":
          return this.$t("__exportData");
        case "externalApiConfig":
          return this.$t("__externalApiConfig");
        case "setting":
          return this.$t("__generalSetup");
        case "userIcon":
          return this.$t("__iconSetting");
        case "metaUtils":
        return this.$t("__metaUtils");
        case "account":
          this.renderKey += 1;
          localStorage.removeItem("_token");
          return this.capitalizeFirstLetter(name);
        default:
          return this.capitalizeFirstLetter(name);
      }
    },
    // Show account name and account icon on top right
    accountName() {
      this.renderKey += 1;
      if (!localStorage._token) {
        return null;
      }
      this.profileImg = localStorage.profileImg;
      let accountName = this.$aes.decrypt(localStorage._token).split(",")[2];
      return accountName;
    },
  },
  data() {
    let isTw = true;
    let langv = this.$cookie.get("language");
    if (langv == null) {
      // cookieに保存されていない -> ブラウザの言語設定を取得
      const browserLang = getBrowserLanguage();
      if (browserLang.startsWith("zh")) {
        switchLang("tw");
      } else {
        isTw = false;
        switchLang("ja");
      }
    } else {
      // cookieに保存されている -> cookieの設定を参照
      if (langv == "ja") {
        isTw = false;
        switchLang("ja");
      } else {
        switchLang("tw");
      }
    }

    return {
      renderKey: 0,
      activeNotifications: false,
      profileImg: localStorage.profileImg,
      chineselang: isTw,
      isAdmin: false,
    };
  },
  async created() {
    this.checkAdmin();
    Hub.listen("auth", (data) => {
      if (data.payload.event === "signIn") {
        this.checkAdmin();
      } else {
        this.isAdmin = false;
      }
    });
  },
  methods: {
    switchJa() {
      this.chineselang = false;
      switchLang("ja");
      this.$root.$refs.richmenulang.loadData();
      this.$cookie.set("language", "ja");
    },
    switchTw() {
      this.chineselang = true;
      switchLang("tw");
      this.$root.$refs.richmenulang.loadData();
      this.$cookie.set("language", "tw");
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    changeAccount() {
      this.$router.push("/account");
    },
    // Check if account is login
    isLogIn() {
      if (!localStorage._token) {
        return false;
      }
      return true;
    },
    // Check if admin is login
    async checkAdmin() {
      try {
        await this.$Amplify.Auth.currentAuthenticatedUser();
        this.isAdmin = true;
      } catch (e) {
        console.log(e);
        this.isAdmin = false;
      }
    },
    isAccToken() {
      if (localStorage._token) {
        return true;
      } else {
        return false;
      }
    },
    // Clean all the data in storage
    async logOut() {
      try {
        await this.$Amplify.Auth.signOut();
      } catch (e) {
        console.log("error signing out: ", e);
      }
      this.renderKey += 1;
      localStorage.clear();
      this.$router.push("/login");
    },
  },
};
</script>
<style>
</style>
