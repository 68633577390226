<template>
  <card class="main-content">
    <!-- content place -->

    <!-- Api Target -->
    <div class="row mt-4 my-2">
      <div class="col-3 my-2">
        {{ $t("__ApiLink") }}
      </div>
      <div class="col-9 my-2">
        <span>{{ apiLink }}</span>
      </div>
    </div>

    <!-- End Point -->
    <div class="row mt-4 my-2">
      <div class="col-3 my-2">{{ $t("__EndPoint") }}</div>
      <div class="row col-9 my-2">
        <p class="mb-2 col-9" id="endPoint-title">{{ endPoint }}</p>
        <span id="endPoint-edit" @click="editEndPoint()">
          <i class="fas fa-pencil-alt cursor-pointer pl-1"></i>
        </span>
        <input
          type="text"
          class="form-control mb-2 col-9 element-hide"
          id="endPoint-input"
          v-model="endPoint"
          @keypress="confirmChangeEndPoint($event)"
        />
      </div>
    </div>

    <!-- Client Id -->
    <div class="row mt-4 my-2">
      <div class="col-3 my-2">{{ $t("__ClientId") }}</div>
      <div class="row col-9 my-2">
        <p class="mb-2 col-9" id="clientId-title">{{ clientId }}</p>
        <span id="clientId-edit" @click="editClientId()">
          <i class="fas fa-pencil-alt cursor-pointer pl-1"></i>
        </span>
        <input
          type="text"
          class="form-control mb-2 col-9 element-hide"
          id="clientId-input"
          v-model="clientId"
          @keypress="confirmChangeClientId($event)"
        />
      </div>
    </div>

    <!-- Key -->
    <div class="row mt-4 my-2">
      <div class="col-3 my-2">Key</div>
      <div class="row col-9 my-2">
        <p class="mb-2 col-9" id="key-title">{{ key }}</p>
        <span id="key-edit" @click="editKey()">
          <i class="fas fa-pencil-alt cursor-pointer pl-1"></i>
        </span>
        <input
          type="text"
          class="form-control mb-2 col-9 element-hide"
          id="key-input"
          v-model="key"
          @keypress="confirmChangeKey($event)"
        />
      </div>
    </div>

    <!-- Parameters -->
    <div class="row mt-4 my-2">
      <div class="col-3 my-2">Request</div>
      <div class="col-6 my-2">
        <div class="row mb-2 align-items-center">
          <div class="row col-6 my-2">
            <p class="col-9 my-2"><b>Parameter</b></p>
          </div>
          <p class="col-4 my-2"><b>Variable</b></p>
          <a
            href="javascript:void(0)"
            class="text-info m-1"
            @click="addParameter()"
            v-if="params.length == 0"
          >
            <i class="fas fa-plus text-info"></i>
          </a>
        </div>
        <div class="row mb-2" v-for="(item, index) in params" :key="index">
          <div class="row col-6 my-2">
            <p class="col-9" :id="'param-title-' + index">
              {{ item.param }}
            </p>
            <span :id="'param-edit-' + index" @click="editParameter(index)">
              <i class="fas fa-pencil-alt cursor-pointer pl-1"></i>
            </span>
            <input
              type="text"
              class="form-control col-9 element-hide"
              :id="'param-input-' + index"
              v-model="item.param"
              @keypress="confirmChangeParameter($event, index)"
            />
          </div>
          <select class="col-4 my-2" v-model="item.variable">
            <option value disabled selected>
              {{ $t("__UserVariableSelectOptional") }}
            </option>
            <option
              v-for="(value, index) in allVariables"
              :value="value"
              :key="'variable-' + index"
            >
              {{ value }}
            </option>
          </select>
          <div class="ml-1">
            <a
              href="javascript:void(0)"
              class="text-danger m-1"
              @click="deleteParameter(index)"
              v-if="params.length > 0"
            >
              <i class="fas fa-minus text-danger"></i>
            </a>
          </div>
          <div class="ml-1">
            <a
              href="javascript:void(0)"
              class="text-info m-1"
              @click="addParameter()"
              v-if="index == params.length - 1"
            >
              <i class="fas fa-plus text-info"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
    <!-- Save -->
    <button
      class="btn btn-outline-success pull-right"
      @click="updateAccountExternalApiConfig()"
      data-translate
    >
      Update
    </button>
    <!-- content place -->
  </card>
</template>
<script>
import { apiFunction } from "../../Axios/functions";

export default {
  data() {
    return {
      adminId: "",
      jwtClientId: "",
      accountId: "",
      allVariables: [],
      apiLink: "CROS",
      endPoint: "",
      clientId: "",
      key: "",
      params: [],
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    // Prepare page content
    async loadData() {
      // Getting required Id with route parameter
      if (localStorage._token) {
        let decrypt = this.$aes.decrypt(localStorage._token).split(",");
        this.adminId = decrypt[0];
        this.accountId = decrypt[1];
      }
      try {
        let user = await this.$Amplify.Auth.currentAuthenticatedUser();
        this.jwtToken = user.getSignInUserSession().getIdToken().jwtToken;
      } catch (e) {
        this.$fire({
          title: this.$t("__authExpired"),
          text: this.$t("__pleaseLogin"),
          type: "error",
        }).then(() => {
          this.$Amplify.Auth.signOut();
          localStorage.clear();
          this.$router.push("/login");
        });
      }
      this.getAllVariables();
      this.getAccountSetting();
    },
    getAccountSetting() {
      let formData = {
        adminId: this.adminId,
      };
      apiFunction.getAccount(formData, this.jwtToken).then((data) => {
        if (data.error) {
          this.$fire({
            title: this.$t("__authExpired"),
            text: this.$t("__pleaseLogin"),
            type: "error",
          }).then(() => {
            this.$Amplify.Auth.signOut();
            localStorage.clear();
            this.$router.push("/login");
          });
        } else {
          let accounts = data.data.Result.accounts;
          accounts.forEach((account) => {
            if (account.accountId == this.accountId) {
              if (account.setting.cros) {
                this.endPoint = account.setting.cros["endpoint"];
                this.clientId = account.setting.cros["clientid"];
                this.key = account.setting.cros["key"];
                this.params.splice(0);
                for (var paramName in account.setting.cros.params) {
                  this.params.push({
                    param: paramName,
                    variable: account.setting.cros.params[paramName],
                  });
                }
              }
            }
          });
        }
      });
    },
    getAllVariables() {
      let formData = {
        adminId: this.adminId,
        accountId: this.accountId,
      };
      // Get variables
      apiFunction.getVariable(formData, this.jwtToken).then((data) => {
        if (data.error) {
          this.$fire({
            title: this.$t("__authExpired"),
            text: this.$t("__pleaseLogin"),
            type: "error",
          });
          this.$router.push("/login");
        } else {
          this.allVariables = data.data.Result.data;
        }
      });
    },
    addParameter() {
      this.params.push({
        param: "",
        variable: "",
      });
    },
    deleteParameter(index) {
      this.params.splice(index, 1);
    },
    updateAccountExternalApiConfig() {
      let formData = {
        adminId: this.adminId,
        accountId: this.accountId,
        setting: {},
      };
      let params = {};
      this.params.forEach((item) => {
        params[item.param] = item.variable;
      });
      formData.setting["cros"] = {
        endPoint: this.endPoint,
        clientId: this.clientId,
        key: this.key,
        params: params,
      };
      apiFunction
        .updateAccountExternalApiConfig(formData, this.jwtToken)
        .then((data) => {
          if (data && data.status == 200) {
            this.$fire({
              title: this.$t("__updatecompleted"),
              text: this.$t("__updatecompleted"),
              type: "success",
              timer: 1500,
            });
          } else {
            this.$fire({
              title: this.$t("__Settingsnotyetsaved"),
              text: this.$t("__oopsSomethingwentwrong"),
              type: "error",
            });
          }
        });
    },
    editEndPoint() {
      $("#endPoint-title").hide();
      $("#endPoint-input").show();
      $("#endPoint-edit").hide();
    },
    confirmChangeEndPoint($event) {
      if ($event.which == 13) {
        $("#endPoint-title").show();
        $("#endPoint-input").hide();
        $("#endPoint-edit").show();
        return false;
      }
    },
    editClientId() {
      $("#clientId-title").hide();
      $("#clientId-input").show();
      $("#clientId-edit").hide();
    },
    confirmChangeClientId($event) {
      if ($event.which == 13) {
        $("#clientId-title").show();
        $("#clientId-input").hide();
        $("#clientId-edit").show();
        return false;
      }
    },
    editKey() {
      $("#key-title").hide();
      $("#key-input").show();
      $("#key-edit").hide();
    },
    confirmChangeKey($event) {
      if ($event.which == 13) {
        $("#key-title").show();
        $("#key-input").hide();
        $("#key-edit").show();
        return false;
      }
    },
    editParameter(index) {
      $("#param-title-" + index).hide();
      $("#param-input-" + index).show();
      $("#param-edit-" + index).hide();
    },
    confirmChangeParameter($event, index) {
      if ($event.which == 13) {
        $("#param-title-" + index).show();
        $("#param-input-" + index).hide();
        $("#param-edit-" + index).show();
        return false;
      }
    },
  },
};
</script>