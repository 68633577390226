import axios from 'axios';

export const apiFunction = {

    // Show number of current nodes in dashboard page
    // ***required fields
    // **
    // "adminId": "string",
    // "accountId": "string"
    // **
    // ***
    getScenario: function (formData, jwtToken) {
        return axios({
            method: "POST",
            url: process.env.VUE_APP_API_ENDPOINT + "getScenario",
            data: formData,
            headers: {
                Authorization: `Bearer ${jwtToken}`
            },
        }).then(res => {
            return res;
        }).catch(e => {
            console.log(e.response);
            return { error: e.response ? e.response : "null" }
        });
    },

    // Get usercount
    // ***required fields
    // **
    // "adminId": "string"
    // "accountId": "string"
    // ***
    getUserCount: function (formData, jwtToken) {
        return axios({
            method: "POST",
            url: process.env.VUE_APP_API_ENDPOINT + "getUserCount",
            data: formData,
            headers: {
                Authorization: `Bearer ${jwtToken}`
            },
        }).then(res => {
            return res;
        }).catch(e => {
            console.log(e);
            return { error: e.response ? e.response : "null" }
        });
    },
    getAccount: function (formData, jwtToken) {
        return axios({
          method: "POST",
          url: process.env.VUE_APP_API_ENDPOINT + "getAccount",
          data: formData,
          headers: {
            Authorization: `Bearer ${jwtToken}`
          },
        }).then(res => {
          return res;
        }).catch(e => {
          console.log(e.response);
          return {error: e.response ? e.response : "null"}
        });
    },
};
