<template>
  <card class="main-content">
    <div class="border border-success rounded px-4 py-4 mb-3">
      <div class="row">
        <div class="col-3 m-3 border rounded-lg user-icon-block shadow" v-for="(item, index) in userIconList" :key="index" @click="editUserIcon(item)">
          <img class="rounded-circle account-type" />
          <div class="text-center pt-4">
            <img class="rounded-circle user-icon-img" :src="item.url" />
          </div>
          <p class="text-center py-3 font-weight-bold">{{ item.name }}</p>
        </div>
      </div>
    </div>
    <div class="btn-follow">
      <a href="javascript:void(0)" style="color: #fff" @click="createUserIcon()">
        <i class="fas fa-plus"></i>
      </a>
    </div>
    <create-user-icon-modal v-on:saveUserIcon="saveUserIcon" v-on:deleteUserIcon="deleteUserIcon" v-bind:saveUserIconData="modalData"
      ref="createUserIconModal"></create-user-icon-modal>
  </card>
</template>
<script>
import { CreateUserIconModal } from "./Modals/index";
import { apiFunction } from "../../Axios/functions";

export default {

  components: {
    CreateUserIconModal,
  },
  data() {
    return {
      adminId: "",
      accountId: "",
      jwtToken: "",
      userIconList: [],
      modalData: {
        id: "",
        name: "",
        url: "",
      },
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    // Prepare page content
    async loadData() {
      // Getting required Id with route parameter
      if (localStorage._token) {
        let decrypt = this.$aes.decrypt(localStorage._token).split(",");
        this.adminId = decrypt[0];
        this.accountId = decrypt[1];
      }
      try {
        let user = await this.$Amplify.Auth.currentAuthenticatedUser();
        this.jwtToken = user.getSignInUserSession().getIdToken().jwtToken;
      } catch (e) {
        this.$fire({
          title: this.$t('__authExpired'),
          text: this.$t('__pleaseLogin'),
          type: "error"
        }).then(() => {
          this.$Amplify.Auth.signOut();
          localStorage.clear();
          this.$router.push("/login");
        });
      }
      this.getUserIconList();
    },
    getUserIconList() {
      let formData = { adminId: this.adminId, accountId: this.accountId };

      apiFunction.getIconUrl(formData, this.jwtToken).then((data) => {
        if (data.error) {
          this.$fire({
            title: this.$t("__authExpired"),
            text: this.$t("__pleaseLogin"),
            type: "error",
          }).then(() => {
            this.$Amplify.Auth.signOut();
            localStorage.clear();
            this.$router.push("/login");
          });
        } else {
          this.userIconList = data.data.Result.data;
        }
      });
    },
    createUserIcon() {
      this.modalData = {
        id: "",
        name: "",
        url: "",
      };
      this.$refs.createUserIconModal.editMode = false;
      $("#create-user-icon-modal").modal("show");
    },
    saveUserIcon() {
      var saveIcon = {
        name: this.modalData.name,
        url: this.modalData.url
      }
      if (this.modalData.id !== "") {
        saveIcon["id"] = this.modalData.id;
      }
      let formData = {
        adminId: this.adminId,
        accountId: this.accountId,
        accountIconUrl: [saveIcon]
      };
      apiFunction.saveIconUrl(formData, this.jwtToken).then((data) => {
        if (data && !data.error) {
          this.$fire({
            title: this.$t("__addedsuccessfully"),
            text: this.$t("__newIconCreated"),
            type: "success",
            timer: 1500,
          }).then((e) => {
            this.getUserIconList();
          });
        } else {
          this.$fire({
            title: this.$t("__Addfailed"),
            text: this.$t("__oopsSomethingwentwrong"),
            type: "error",
          });
        }
      });
    },
    deleteUserIcon() {
      if (this.modalData.id == "") {
        return;
      }
      let formData = {
        adminId: this.adminId,
        accountId: this.accountId,
        accountIconUrl: [{id: this.modalData.id}]
      };
      apiFunction.deleteIconUrl(formData, this.jwtToken).then((data) => {
        if (data && !data.error) {
          this.$fire({
            title: this.$t("__successfullydeleted"),
            text: this.$t("__Yourdatahasbeensuccessfullydeleted"),
            type: "success",
            timer: 1500,
          }).then((e) => {
            this.getUserIconList();
          });
        } else {
          this.$fire({
            title: this.$t("__failedtodelete"),
            text: this.$t("__oopsSomethingwentwrong"),
            type: "error",
          });
        }
      });
    },
    editUserIcon(userIcon) {
      this.modalData = {
        id: userIcon.id,
        name: userIcon.name,
        url: userIcon.url
      };
      this.$refs.createUserIconModal.editMode = true;
      $("#create-user-icon-modal").modal("show");
    }
  },
};
</script>
<style></style>
