import axios from 'axios';

export const apiFunction = {
  getScenario: function (formData, jwtToken) {
    return axios({
      method: "POST",
      url: process.env.VUE_APP_API_ENDPOINT + "getScenario",
      data: formData,
      headers: {
          Authorization: `Bearer ${jwtToken}`
      },
    }).then(res => {
      return res;
    }).catch(e => {
      console.log(e.response);
      return {error: e.response ? e.response : "null"}
    });
  },
  getAccount: function (formData, jwtToken) {
    return axios({
      method: "POST",
      url: process.env.VUE_APP_API_ENDPOINT + "getAccount",
      data: formData,
      headers: {
        Authorization: `Bearer ${jwtToken}`
      },
    }).then(res => {
      return res;
    }).catch(e => {
      console.log(e.response);
      return {error: e.response ? e.response : "null"}
    });
  },
  getIGAccount: function (appToken) {
    return axios({
      method: "GET",
      url: `https://graph.facebook.com/v18.0/me?fields=instagram_business_account&access_token=${appToken}`,
    }).then(res => {
      return res;
    }).catch(e => {
      console.log(e.response);
      return {error: e.response ? e.response : "null"}
    });
  },
  getIGAccountName: function (appToken,igAccountId) {
    return axios({
      method: "GET",
      url: `https://graph.facebook.com/v18.0/${igAccountId}?fields=username&access_token=${appToken}`,
    }).then(res => {
      return res;
    }).catch(e => {
      console.log(e.response);
      return {error: e.response ? e.response : "null"}
    });
  },
  getAllIGPosts: async function(id, appToken) {
    let allPosts = [];
    let nextUrl = `https://graph.facebook.com/v18.0/${id}/media?access_token=${appToken}`;
    try {
      while (nextUrl) {
        const postData = await axios.get(nextUrl);
        const posts = postData.data.data;
        allPosts = allPosts.concat(posts);
        if(postData.data.paging){
          nextUrl = postData.data.paging.next;
        };
      };
      return allPosts;
    } catch (error) {
      console.error("Error fetching IG posts:", error);
      return { error: error.response ? error.response : "null" };
    }
  },
  getIGPost: function (appToken,id) {
    return axios({
      method: "GET",
      url: `https://graph.facebook.com/v18.0/${id}?fields=username,media_type,caption,media_url,comments&access_token=${appToken}`,
    }).then(res => {
      return res;
    }).catch(e => {
      console.log(e.response);
      return {error: e.response ? e.response : "null"}
    });
  },
  getUtils(formData, jwtToken){
    return axios({
      method: "POST",
      url: process.env.VUE_APP_API_ENDPOINT + "getMetaUtil",
      data: formData,
      headers: {
          Authorization: `Bearer ${jwtToken}`
      },
    }).then(res => {
      console.log(res)
      return res;
    }).catch(e => {
      console.log(e.response);
      return {error: e.response ? e.response : "null"}
    });
  },
  saveUtil(formData, jwtToken){
    return axios({
      method: "POST",
      url: process.env.VUE_APP_API_ENDPOINT + "saveMetaUtil",
      data: formData,
      headers: {
          Authorization: `Bearer ${jwtToken}`
      },
    }).then(res => {
      console.log(res)
      return res;
    }).catch(e => {
      console.log(e.response);
      return {error: e.response ? e.response : "null"}
    });
  },
  deleteUtil(formData, jwtToken){
    return axios({
      method: "POST",
      url: process.env.VUE_APP_API_ENDPOINT + "deleteMetaUtil",
      data: formData,
      headers: {
          Authorization: `Bearer ${jwtToken}`
      },
    }).then(res => {
      console.log(res)
      return res;
    }).catch(e => {
      console.log(e);
      return {error: e.response ? e.response : "null"}
    });
  }
};
