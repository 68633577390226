var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("card", { staticClass: "main-content" }, [
    _c("div", { staticClass: "row mt-4 my-2" }, [
      _c("div", { staticClass: "col-3 my-2" }, [
        _vm._v("\n      " + _vm._s(_vm.$t("__ApiLink")) + "\n    ")
      ]),
      _c("div", { staticClass: "col-9 my-2" }, [
        _c("span", [_vm._v(_vm._s(_vm.apiLink))])
      ])
    ]),
    _c("div", { staticClass: "row mt-4 my-2" }, [
      _c("div", { staticClass: "col-3 my-2" }, [
        _vm._v(_vm._s(_vm.$t("__EndPoint")))
      ]),
      _c("div", { staticClass: "row col-9 my-2" }, [
        _c(
          "p",
          { staticClass: "mb-2 col-9", attrs: { id: "endPoint-title" } },
          [_vm._v(_vm._s(_vm.endPoint))]
        ),
        _c(
          "span",
          {
            attrs: { id: "endPoint-edit" },
            on: {
              click: function($event) {
                return _vm.editEndPoint()
              }
            }
          },
          [_c("i", { staticClass: "fas fa-pencil-alt cursor-pointer pl-1" })]
        ),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.endPoint,
              expression: "endPoint"
            }
          ],
          staticClass: "form-control mb-2 col-9 element-hide",
          attrs: { type: "text", id: "endPoint-input" },
          domProps: { value: _vm.endPoint },
          on: {
            keypress: function($event) {
              return _vm.confirmChangeEndPoint($event)
            },
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.endPoint = $event.target.value
            }
          }
        })
      ])
    ]),
    _c("div", { staticClass: "row mt-4 my-2" }, [
      _c("div", { staticClass: "col-3 my-2" }, [
        _vm._v(_vm._s(_vm.$t("__ClientId")))
      ]),
      _c("div", { staticClass: "row col-9 my-2" }, [
        _c(
          "p",
          { staticClass: "mb-2 col-9", attrs: { id: "clientId-title" } },
          [_vm._v(_vm._s(_vm.clientId))]
        ),
        _c(
          "span",
          {
            attrs: { id: "clientId-edit" },
            on: {
              click: function($event) {
                return _vm.editClientId()
              }
            }
          },
          [_c("i", { staticClass: "fas fa-pencil-alt cursor-pointer pl-1" })]
        ),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.clientId,
              expression: "clientId"
            }
          ],
          staticClass: "form-control mb-2 col-9 element-hide",
          attrs: { type: "text", id: "clientId-input" },
          domProps: { value: _vm.clientId },
          on: {
            keypress: function($event) {
              return _vm.confirmChangeClientId($event)
            },
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.clientId = $event.target.value
            }
          }
        })
      ])
    ]),
    _c("div", { staticClass: "row mt-4 my-2" }, [
      _c("div", { staticClass: "col-3 my-2" }, [_vm._v("Key")]),
      _c("div", { staticClass: "row col-9 my-2" }, [
        _c("p", { staticClass: "mb-2 col-9", attrs: { id: "key-title" } }, [
          _vm._v(_vm._s(_vm.key))
        ]),
        _c(
          "span",
          {
            attrs: { id: "key-edit" },
            on: {
              click: function($event) {
                return _vm.editKey()
              }
            }
          },
          [_c("i", { staticClass: "fas fa-pencil-alt cursor-pointer pl-1" })]
        ),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.key,
              expression: "key"
            }
          ],
          staticClass: "form-control mb-2 col-9 element-hide",
          attrs: { type: "text", id: "key-input" },
          domProps: { value: _vm.key },
          on: {
            keypress: function($event) {
              return _vm.confirmChangeKey($event)
            },
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.key = $event.target.value
            }
          }
        })
      ])
    ]),
    _c("div", { staticClass: "row mt-4 my-2" }, [
      _c("div", { staticClass: "col-3 my-2" }, [_vm._v("Request")]),
      _c(
        "div",
        { staticClass: "col-6 my-2" },
        [
          _c("div", { staticClass: "row mb-2 align-items-center" }, [
            _c("div", { staticClass: "row col-6 my-2" }, [
              _c("p", { staticClass: "col-9 my-2" }, [
                _c("b", [_vm._v("Parameter")])
              ])
            ]),
            _c("p", { staticClass: "col-4 my-2" }, [
              _c("b", [_vm._v("Variable")])
            ]),
            _vm.params.length == 0
              ? _c(
                  "a",
                  {
                    staticClass: "text-info m-1",
                    attrs: { href: "javascript:void(0)" },
                    on: {
                      click: function($event) {
                        return _vm.addParameter()
                      }
                    }
                  },
                  [_c("i", { staticClass: "fas fa-plus text-info" })]
                )
              : _vm._e()
          ]),
          _vm._l(_vm.params, function(item, index) {
            return _c("div", { key: index, staticClass: "row mb-2" }, [
              _c("div", { staticClass: "row col-6 my-2" }, [
                _c(
                  "p",
                  {
                    staticClass: "col-9",
                    attrs: { id: "param-title-" + index }
                  },
                  [
                    _vm._v(
                      "\n            " + _vm._s(item.param) + "\n          "
                    )
                  ]
                ),
                _c(
                  "span",
                  {
                    attrs: { id: "param-edit-" + index },
                    on: {
                      click: function($event) {
                        return _vm.editParameter(index)
                      }
                    }
                  },
                  [
                    _c("i", {
                      staticClass: "fas fa-pencil-alt cursor-pointer pl-1"
                    })
                  ]
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: item.param,
                      expression: "item.param"
                    }
                  ],
                  staticClass: "form-control col-9 element-hide",
                  attrs: { type: "text", id: "param-input-" + index },
                  domProps: { value: item.param },
                  on: {
                    keypress: function($event) {
                      return _vm.confirmChangeParameter($event, index)
                    },
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(item, "param", $event.target.value)
                    }
                  }
                })
              ]),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: item.variable,
                      expression: "item.variable"
                    }
                  ],
                  staticClass: "col-4 my-2",
                  on: {
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        item,
                        "variable",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    }
                  }
                },
                [
                  _c(
                    "option",
                    { attrs: { value: "", disabled: "", selected: "" } },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("__UserVariableSelectOptional")) +
                          "\n          "
                      )
                    ]
                  ),
                  _vm._l(_vm.allVariables, function(value, index) {
                    return _c(
                      "option",
                      { key: "variable-" + index, domProps: { value: value } },
                      [
                        _vm._v(
                          "\n            " + _vm._s(value) + "\n          "
                        )
                      ]
                    )
                  })
                ],
                2
              ),
              _c("div", { staticClass: "ml-1" }, [
                _vm.params.length > 0
                  ? _c(
                      "a",
                      {
                        staticClass: "text-danger m-1",
                        attrs: { href: "javascript:void(0)" },
                        on: {
                          click: function($event) {
                            return _vm.deleteParameter(index)
                          }
                        }
                      },
                      [_c("i", { staticClass: "fas fa-minus text-danger" })]
                    )
                  : _vm._e()
              ]),
              _c("div", { staticClass: "ml-1" }, [
                index == _vm.params.length - 1
                  ? _c(
                      "a",
                      {
                        staticClass: "text-info m-1",
                        attrs: { href: "javascript:void(0)" },
                        on: {
                          click: function($event) {
                            return _vm.addParameter()
                          }
                        }
                      },
                      [_c("i", { staticClass: "fas fa-plus text-info" })]
                    )
                  : _vm._e()
              ])
            ])
          })
        ],
        2
      )
    ]),
    _c(
      "button",
      {
        staticClass: "btn btn-outline-success pull-right",
        attrs: { "data-translate": "" },
        on: {
          click: function($event) {
            return _vm.updateAccountExternalApiConfig()
          }
        }
      },
      [_vm._v("\n    Update\n  ")]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }