<template>
  <card class="main-content">
    <!-- search -->
    <div class="row">
      <div class="ml-auto py-2 pr-4">
        <input
          id="search-box"
          type="text"
          class="search-box"
          name="q"
          v-model="search"
        />
        <label for="search-box">
          <span class="fas fa-search search-icon"></span>
        </label>
      </div>
    </div>
    <!-- search -->
    <!-- content start -->
    <div class="row mt-4 mb-1">
      <div class="ml-auto mr-4">
        <a
          href="javascript:void(0)"
          style="color: #009688"
          @click="$router.push('/segment')"
          >{{ $t("__Userclassification") }}</a
        >
        <span class="px-2">/</span>
        <a
          href="javascript:void(0)"
          @click="$router.push('/segment/userList')"
          >{{ $t("__Userlist") }}</a
        >
      </div>
    </div>
    <segment-table
      type="hover table-bordered"
      :adminId="adminId"
      :jwtToken="jwtToken"
      :accountId="accountId"
      :data="table.data"
      :columns="table.columns"
      v-bind:newSegment="modalData"
      v-bind:currentNumber="allSegment.length"
      v-bind:allScenario="allScenario"
    ></segment-table>
    <!-- content end -->
    <div class="infinite-wrapper">
      <infinite-loading
        :forceUseInfiniteWrapper="true"
        @infinite="infiniteHandler"
      >
        <span slot="no-more"> No More Data </span>
        <span slot="no-results"></span>
      </infinite-loading>
    </div>
  </card>
</template>
<script>
import { CreatePushModal } from "./Modals/index";
import { SegmentTable } from "./Components/index";
import InfiniteLoading from "vue-infinite-loading";
import axios from "axios";

const tableData = [];

export default {
  components: {
    SegmentTable,
    CreatePushModal,
    InfiniteLoading,
  },
  watch: {
    search: {
      handler: function (val, oldval) {
        this.buildTableData(val);
      },
    },
  },
  data() {
    return {
      search: "",
      allSegment: [],
      adminId: "",
      jwtToken: "",
      accountId: "",
      table: {
        columns: [],
        data: [...tableData],
      },
      modalData: {
        segmentName: "",
        segmentConditions: [],
        segmentUsers: [],
        modalBtn: this.$t("__setup"),
      },
      segmentLastEvaluatedKey: null,
      allScenario: [],
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    async loadData() {
      // Getting required Id with route parameter
      if (localStorage._token) {
        let decrypt = this.$aes.decrypt(localStorage._token).split(",");
        this.adminId = decrypt[0];
        this.accountId = decrypt[1];
      }
      try {
        let user = await this.$Amplify.Auth.currentAuthenticatedUser();
        this.jwtToken = user.getSignInUserSession().getIdToken().jwtToken;
      } catch (e) {
        this.$fire({
          title: this.$t("__authExpired"),
          text: this.$t("__pleaseLogin"),
          type: "error",
        }).then(() => {
          this.$Amplify.Auth.signOut();
          localStorage.clear();
          this.$router.push("/login");
        });
      }
      this.getAllScenario();
    },
    getAllScenario() {
      // Get all scenario
      let formData = { adminId: this.adminId, accountId: this.accountId };
      axios({
        method: "POST",
        url: process.env.VUE_APP_API_ENDPOINT + "getScenario",
        data: formData,
        headers: {
          Authorization: `Bearer ${this.jwtToken}`,
        },
      })
        .then((res) => {
          let tempArr = [];
          res.data.Result.data.forEach((scenario) => {
            tempArr.push(scenario);
          });
          tempArr.sort((s1, s2) => {
            return s1.scenario.scenarioName.localeCompare(
              s2.scenario.scenarioName
            );
          });
          this.allScenario = tempArr;
        })
        .catch((e) => {
          console.log(e);
          this.$fire({
            title: this.$t("__authExpired"),
            text: this.$t("__pleaseLogin"),
            type: "error",
          }).then(() => {
            this.$Amplify.Auth.signOut();
            localStorage.clear();
            this.$router.push("/login");
          });
        });
    },
    async fetchSegmentList(lastEvaluatedKey = null) {
      let formData = { adminId: this.adminId, accountId: this.accountId };
      if (lastEvaluatedKey) {
        formData["lastEvaluatedKey"] = lastEvaluatedKey;
      }
      return axios({
        method: "POST",
        url: process.env.VUE_APP_API_ENDPOINT + "getUserSegment",
        data: formData,
        headers: {
          Authorization: `Bearer ${this.jwtToken}`,
        },
      })
        .then((res) => {
          return res;
        })
        .catch((e) => {
          console.log(e);
          this.$fire({
            title: this.$t("__authExpired"),
            text: this.$t("__pleaseLogin"),
            type: "error",
          }).then(() => {
            this.$Amplify.Auth.signOut();
            localStorage.clear();
            this.$router.push("/login");
          });
          return null;
        });
    },
    buildTableData(regVal = "") {
      let reg = new RegExp(regVal);
      let searchResult = [];

      $.each(this.allSegment, function (i, segment) {
        $.each(segment.tags, function (i, tag) {
          if (reg.test(segment.name) == false && reg.test(tag.name) == true) {
            searchResult.push(segment);
          }
        });
        if (reg.test(segment.name) == true) {
          searchResult.push(segment);
        }
      });
      let sortedResult = this.sortByUpdateTime(searchResult);
      // Push default segment to the beginning of table.
      let defaultSegmentIdx = sortedResult.findIndex(segment => segment.defaultUsersSegment == true);
      if (defaultSegmentIdx > -1) {
        let defautlSegment = sortedResult[defaultSegmentIdx];
        sortedResult.splice(defaultSegmentIdx, 1);
        sortedResult = [defautlSegment].concat(sortedResult);
      }
      this.table.data = sortedResult;
    },
    // Sort table row by update time
    sortByUpdateTime(array) {
      array.sort(function (a, b) {
        var dateA = new Date(a.updateTime),
          dateB = new Date(b.updateTime);
        return dateA < dateB ? 1 : -1;
      });
      return array;
    },
    async infiniteHandler($state) {
      let res = await this.fetchSegmentList(this.segmentLastEvaluatedKey);
      if (res) {
        this.allSegment.push(...res.data.Result.data);
        this.segmentLastEvaluatedKey = res.data.Result.lastEvaluatedKey;
        this.buildTableData(this.search);
        if (this.segmentLastEvaluatedKey) {
          return $state.loaded();
        }
      }
      return $state.complete();
    },
  },
};
</script>
<style>
</style>
