var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "card",
    { staticClass: "main-content" },
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "ml-auto py-2 pr-4" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.search,
                expression: "search"
              }
            ],
            staticClass: "search-box",
            attrs: { id: "search-box", type: "text", name: "q" },
            domProps: { value: _vm.search },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.search = $event.target.value
              }
            }
          }),
          _c("label", { attrs: { for: "search-box" } }, [
            _c("span", { staticClass: "fas fa-search search-icon" })
          ])
        ])
      ]),
      _c("div", { staticClass: "row mt-4 mb-1" }, [
        _c("div", { staticClass: "ml-auto mr-4" }, [
          _c(
            "a",
            {
              staticStyle: { color: "#009688" },
              attrs: { href: "javascript:void(0)" },
              on: {
                click: function($event) {
                  return _vm.$router.push("/segment")
                }
              }
            },
            [_vm._v(_vm._s(_vm.$t("__Userclassification")))]
          ),
          _c("span", { staticClass: "px-2" }, [_vm._v("/")]),
          _c(
            "a",
            {
              attrs: { href: "javascript:void(0)" },
              on: {
                click: function($event) {
                  return _vm.$router.push("/segment/userList")
                }
              }
            },
            [_vm._v(_vm._s(_vm.$t("__Userlist")))]
          )
        ])
      ]),
      _c("segment-table", {
        attrs: {
          type: "hover table-bordered",
          adminId: _vm.adminId,
          jwtToken: _vm.jwtToken,
          accountId: _vm.accountId,
          data: _vm.table.data,
          columns: _vm.table.columns,
          newSegment: _vm.modalData,
          currentNumber: _vm.allSegment.length,
          allScenario: _vm.allScenario
        }
      }),
      _c(
        "div",
        { staticClass: "infinite-wrapper" },
        [
          _c(
            "infinite-loading",
            {
              attrs: { forceUseInfiniteWrapper: true },
              on: { infinite: _vm.infiniteHandler }
            },
            [
              _c("span", { attrs: { slot: "no-more" }, slot: "no-more" }, [
                _vm._v(" No More Data ")
              ]),
              _c("span", { attrs: { slot: "no-results" }, slot: "no-results" })
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }