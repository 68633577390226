<template>
  <div class="border p-2 mb-2">
    <label for=""><b>{{this.$t('__segment')}}</b></label> 
    <div>
      <a href="javascript:void(0)" @click="deleteCondition" class="text-danger float-right userlist-delete-icon-a">
        <svg class="svg-inline--fa fa-trash text-danger userlist-delete-icon" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="trash" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg=""><path fill="currentColor" d="M432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16zM53.2 467a48 48 0 0 0 47.9 45h245.8a48 48 0 0 0 47.9-45L416 128H32z"></path></svg><!-- <i class="fas fa-trash text-danger"></i> Font Awesome fontawesome.com -->
      </a>
    </div>    
    <form>
      
      <div class="form-group row">
        <label for="staticEmail" class="col-sm-2 col-form-label">{{this.$t('__userlist_matchType')}}</label>
        <div class="col-sm-10">
          <select class="custom-select" v-model="matchType" @change="changeMatch">
            <option value="match_any">{{this.$t('__userlist_matchAny')}}</option>
            <option value="match_all">{{this.$t('__userlist_matchAll')}}</option>
            <option value="not_match_any">{{this.$t('__userlist_notMatchAny')}}</option>
            <option value="not_match_all">{{this.$t('__userlist_notMatchAll')}}</option>
          </select>
        </div>
      </div>
      <div class="form-group row">
        <label for="inputPassword" class="col-sm-2 col-form-label">{{this.$t('__userlist_segmentName')}}</label>
        <div class="container ">
          <div class="col-sm-10 row align-items-start">
            <div class="col custom-control custom-checkbox" v-for="(item, index) in condition.segments">
            <input type="checkbox" class="custom-control-input" 
                    v-model="item.isChecked"  :id="'usertag-'+condition.id+'-'+index" @change="select(item,index)">
            <label class="custom-control-label" :for="'usertag-'+condition.id+'-'+index">
              <span class="tag bg-info"
                          >{{ item.name }}</span>
            </label>
          </div>  
          </div>
        </div>
      </div>
    </form>
    
  </div>
</template>
<script>
export default {
  name: "FilterUserSegment",
  props: {
    condition: Object
  },

  data() {
    return {
      matchType: 'match_any'
    }
  },
  methods: {
    
    select(segment,index){
      if(this.condition.filters.segments == undefined){
        this.condition.filters.segments = [];
      }

      let findSegment = this.condition.filters.segments.find(t => t.name == segment.name);
      if(findSegment == null){
        if(segment.isChecked == true){
          this.condition.filters.segments.push(segment);
        }
      }else{
        if(segment.isChecked == false){
          // if checkbox is unchecked, and findTag is not null, remove it from condition
          this.condition.filters.segments = this.condition.filters.segments.filter(t => t.name != segment.name);
        }

      }
      //this.$emit('updateCondition', this.condition);
    },
    changeMatch(){
      this.condition.matchType = this.matchType;
      //this.$emit('updateCondition', this.condition);
    },
    deleteCondition(){
      this.$emit('deleteCondition', this.condition);
    }
  }
};
</script>
<style>
</style>
